import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

import { AlertController, Platform, PopoverController, ToastController } from '@ionic/angular';
import { NewShoppingCartItem, ShoppingCart } from '../shared/models/ShoppingCart.models';
import { AuthService } from '../shared/services/auth.service';
import { SCDeleteItemPopover } from './shopping-cart-popover/shopping-cart-popover.component';
import { UserService } from '../shared/services/User.service';
import { ProdottiInteractionService } from '../shared/services/prodotti-interaction.service';
import { ConstantUtilsService } from '../shared/services/constant-utils.service';
import { ShoppingCartManagerService } from '../shared/services/shopping-cart-manager.service';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss'],
})
export class ShoppingCartComponent implements OnInit {

  title: string = 'Carrello'
  hasCartItems: boolean = false;
  isFetching: boolean = false;
  userLoggedIn: boolean = false;
  localStorageKeys: string[] = [];
  noResults: boolean = true;
  resArray: any = [];
  error: any;
  pop: any;
  private shoppingCartEdited: boolean = false;
  total: number = 0.0;
  subtotal: number = 0.0;
  trasporto_total: number = 0.0;
  hasShippingCosts: boolean = false;
  shippingCosts = {};
  costPerVendor = {};   // Importo totale speso per singolo venditore (serve per check sul costo di spedizione gratuito)
  shippingCostsSize = 0;
  shippingCostTotal: number = 0;
  Cs: number = 0;
  private IVA_MULTIPLIER: number = 1.22;
  private STRIPE_MULTIPLIER: number = 0.014;
  private STRIPE_CONSTANT: number = 0.25;
  private SPEDIZIONE_GRATUITA: number = 50.0;
  nElementi: number = 0;
  // Dict di scelte per la UI, key = prodotto.id | value = choice object
  choicesCartDict = {}; 

  cart: any[] = [];

  constructor(
    public constantUtils: ConstantUtilsService,
    private router: Router, public platform: Platform,
    private authService: AuthService, private alertController: AlertController,
    private userService: UserService, private toastController: ToastController,
    private popover: PopoverController, private logger: NGXLogger,
    private prodottiInteractionService: ProdottiInteractionService,
    private shoppingCartManagerService: ShoppingCartManagerService) { }

  ngOnInit() {
    this.logger.log('ShoppingCartComponent: -----> onInit starts <-----');
  }

  ionViewWillEnter(){
    this.logger.log('ShoppingCartComponent: -----> ionViewWillEnter starts <-----');
    this.logger.log('isLoggedIn() -> '+this.authService.isLoggedIn());
    this.shoppingCartEdited = false;
    if(this.authService.isLoggedIn()){
      // DOC: Valuto se aggiornare il token prima di richiedere i dati della pagina al backend
      // Se il token è scaduto, allora eseguo un refresh e poi chiedo i dati..
      if(this.authService.hasToRefreshBasicAuth()){
        this.authService.refreshBasicAuthToken().then(
          go => {
            // User is logged in
            this.userLoggedIn = true;
            this.hasCartItems = false;
            this.cart = [];
            this.localStorageKeys = [];
            
            this.getShoppingCartItems();
          }
        )
      }else{
        // ..Altrimenti chiedo i dati senza eseguire il refresh
        // User is logged in
        this.userLoggedIn = true;
        this.hasCartItems = false;
        this.cart = [];
        this.localStorageKeys = [];
        
        this.getShoppingCartItems();
      }
    }else{
      this.userLoggedIn = false;
      this.localShoppingCartRetriever();
    }
  }

  ionViewDidEnter(){
    this.logger.log('ShoppingCartComponent: ionViewDidEnter started');

    // Controllo se ci sono ordini zombie nel carrello
    if(this.shoppingCartManagerService.zombie){
      this.logger.log('..presenti ordini zombie');
      this.infoZombieAlert();
    }
  }

  async infoAlert(message: string){
    const alert = await this.alertController.create({
      header: 'Carrello aggiornato',
      cssClass: 'alert-info-popup-style',
      subHeader: ' ',
      message: message,
      buttons: [
        {
          text: 'OK',
          cssClass: 'alert-info-popup-btn-style'
        }
      ]
    });
  
    await alert.present();
  }

  async infoZombieAlert(){
    const alert = await this.alertController.create({
      header: 'Carrello aggiornato',
      cssClass: 'alert-info-popup-style',
      subHeader: ' ',
      message: 'Il checkout per alcuni prodotti non è stato completato e/o è scaduto, per cui ci siamo preoccupati di recuperarli e inserirli nuovamente nel carrello per te! Puoi confermare l\'acquisto procedendo alla schermata di checkout o eliminarli dal carrello tramite appositi pulsanti.',
      buttons: [
        {
          text: 'OK',
          cssClass: 'alert-info-popup-btn-style'
        }
      ]
    });
  
    this.shoppingCartManagerService.zombie = false;
    await alert.present();
  }

  localShoppingCartRetriever(){
    this.logger.log('ShoppingCartComponent: localShoppingCartRetriever called...');

    this.storageValues();
  }

  getShoppingCartItems(){
    if(this.userService.userIsVendor()){
      return;
    }
    
    this.logger.log('ShoppingCartComponent: getShoppingCartItems called...');
    this.isFetching = true;
    this.shoppingCartManagerService.getShoppingCartContent('ShoppingCartComponent').subscribe(
      posts => {
        this.isFetching = false;
        this.error = null;
        this.cart = this.cart.concat(posts);
        this.logger.log(this.cart);
        if(this.cart!=null && this.cart.length>0){
          this.hasCartItems = true;
          this.noResults = false;
          this.computeTotalThroughPv(true);
          this.prodottiInteractionService.announceAmount(this.cart.length);
          // Prendo le scelte dei prodotti (se presenti)
          this.setChoices();
          // Controllo se ci sono ordini zombie nel carrello
          if(this.shoppingCartManagerService.zombie){
            this.logger.log('Carrello recuperato ...presenti ordini zombie');
            this.infoZombieAlert();
          }
        }else{
          this.noResults = true;
          this.prodottiInteractionService.announceAmount(0);
        }
      },
      error => {
        this.isFetching = false;
        this.hasCartItems = false;
        this.error = error.message;
        this.logger.log('ShoppingCartComponent: GET..... an error occurred');
      }
    );
    
  }

  private setChoices(){
    for(let c of this.cart){
      for(let opt of c.prodotto.options){
        for(let choice of opt.choices){
          if(choice.id==c.choice_id){
            this.choicesCartDict[c.choice_id] = choice;
          }
        }
      }
    }
    this.logger.log('ShoppingCartComponent: dict di scelte nel carrello: '+JSON.stringify(this.choicesCartDict)); // DEBUG
  }

  cleanSC(){
    // Method to remove items and or erroneous amounts 
    this.logger.log('ShoppingCartComponent: ...cleaning SC');
    var amount_changed = false;
    var list_changed = false;
    const logged_user = this.authService.isLoggedIn();
    for(let item of this.cart){
      if(logged_user){
        // Behavior for logged users
        if(item.prodotto && Number(item.prodotto['quantita_disponibile'])==0){
          item['quantità'] = 0;
          list_changed = true;
          this.logger.log('---> Una quantità azzerata per prodotto esaurito');
        }else if(item.prodotto && Number(item['quantità']) > Number(item.prodotto['quantita_disponibile'])){
          item['quantità'] = item.prodotto['quantita_disponibile'];
          amount_changed = true;
          this.logger.log('---> Una quantità modificata per quantità disponibile variata');
        }
      }else{
        // Behavior for unlogged users
        // DO NOTHING... 
        // Because you have to log in in order to continue
        // After log in, we get more data and we are able to check the amounts!
      }
    }
    if(list_changed){
      this.infoAlert('Alcuni prodotti sono terminati, abbiamo quindi azzerato la loro quantità nel tuo carrello! Puoi eliminarli dal carrello tramite l\'apposito pulsante.');
    }else if(amount_changed){
      this.infoAlert('Alcuni prodotti stanno terminando, abbiamo aggiornato la quantità acquistabile nel tuo carrello. Non lasciarteli scappare!');
    }
    this.logger.log('ShoppingCartComponent: ...cleaning end');
  }

  computeTotalThroughPv(fromInit: boolean){
    this.logger.log('ShoppingCartComponent: ...computing total');
    if(fromInit){
      this.cleanSC();
    }
    this.total = 0.0;
    this.subtotal = 0.0;
    this.shippingCosts = {};
    this.costPerVendor = {};
    this.shippingCostsSize = 0;
    var pricesSum = 0;
    this.shippingCostTotal = 0;
    this.Cs = 0;
    this.nElementi = 0;
    var isLoggedIn = this.authService.isLoggedIn();
    for(let i of this.cart){
      if(i['costo'] && i['prezzo_vetrina'] && i['prezzo_trasporto'] && i['quantità']){
        this.nElementi = this.nElementi + Number(i['quantità']);
        this.logger.log('prezzo_vetrina i: '+ i['prezzo_vetrina']);
        this.logger.log('prezzo_trasporto i: '+ i['prezzo_trasporto']);
        this.subtotal = this.subtotal + Number(i['prezzo_vetrina'] * i['quantità']);
        pricesSum = pricesSum + i['prezzo_vetrina'] * i['quantità'];

        // Aggiorno la matrice della spesa per singolo venditore
        if(isLoggedIn){
          if(!this.costPerVendor[i.prodotto.venditore.id]){
            this.costPerVendor[i.prodotto.venditore.id] = Number(i['prezzo_vetrina'] * i['quantità']);
          }else{
            this.costPerVendor[i.prodotto.venditore.id] = this.costPerVendor[i.prodotto.venditore.id] + Number(i['prezzo_vetrina'] * i['quantità']);
          }
          this.logger.log('ID Venditore: '+i.prodotto.venditore.id);  // DEBUG
        }else{
          if(!this.costPerVendor[i['venditoreId']]){
            this.costPerVendor[i['venditoreId']] = Number(i['prezzo_vetrina'] * i['quantità']);
          }else{
            this.costPerVendor[i['venditoreId']] = this.costPerVendor[i['venditoreId']] + Number(i['prezzo_vetrina'] * i['quantità']);
          }
          this.logger.log('ID Venditore: '+i['venditoreId']);  // DEBUG
        } 

        // Aggiorno la matrice dei costi di spedizione
        if(isLoggedIn && (!this.shippingCosts[i.prodotto.venditore.id] || this.shippingCosts[i.prodotto.venditore.id]<i.prezzo_trasporto)){
          this.shippingCosts[i.prodotto.venditore.id] = Number(i.prezzo_trasporto);
          this.logger.log('Nuovo costo T = '+this.shippingCosts[i.prodotto.venditore.id]+' per il venditore: '+i.prodotto.venditore.id);
        }else if(!isLoggedIn && (!this.shippingCosts[i['venditoreId']] || this.shippingCosts[i['venditoreId']]<i['prezzo_trasporto'])){
          this.shippingCosts[i['venditoreId']] = Number(i['prezzo_trasporto']);
          this.logger.log('Nuovo costo T = '+this.shippingCosts[i['venditoreId']]+' per il venditore: '+i['venditoreId']);
        }
      }
      if(!i['costo_item'] && i['quantità'] && i['prezzo_vetrina']){
        // Se non esiste il parziale per l'item (caso iniziale), allora lo creo
        i['costo_item'] = this.roundTo(i['quantità'] * i['prezzo_vetrina'],1);
      }
    }

    this.logger.log(this.costPerVendor);   // DEBUG

    this.logger.log('pricesSum = '+pricesSum);
    this.logger.log('Subtotal = '+this.subtotal);
    for(let Tmax_key in this.shippingCosts){
      if(this.costPerVendor[Tmax_key]<this.SPEDIZIONE_GRATUITA){
        // Solo se la spesa per venditore è minore della soglia di SPEDIZ GRATUITA, allora aggiungo il T max per quel venditore
        this.logger.log('INCLUSO il costo di trasporto: '+this.shippingCosts[Tmax_key]+' per venditore = '+Tmax_key);
        this.shippingCostTotal= this.shippingCostTotal + this.shippingCosts[Tmax_key];
        this.shippingCostsSize = this.shippingCostsSize + 1;
      }else{
        this.logger.log('Per il venditore = '+Tmax_key+' non ho incluso costi');
      }
    }
    if(this.shippingCostsSize>0){
      this.hasShippingCosts = true;
    }else{
      this.hasShippingCosts = false;
    }
    this.logger.log('length del dict dei costi di spedizione = '+this.shippingCostsSize);
    this.logger.log('costo di spedizione totale = '+this.shippingCostTotal);
    if(this.subtotal>0){
      this.Cs = this.STRIPE_MULTIPLIER * ( pricesSum + this.shippingCostTotal ) + this.STRIPE_CONSTANT;
    }
    this.logger.log('costo di transazione totale = '+this.Cs);
    // Cs not used in total computation
    this.total = this.roundTo(this.roundTo(this.subtotal,1) + this.shippingCostTotal,1);
    this.logger.log('Total = '+this.total);
  }

  deleteItem(index: number){
    this.logger.log('ShoppingCartComponent: user clicked on remove-item button..... index = '+index);
    this.CreatePopover(null, index);
  }

  incrementItemPv(index: number){
    this.logger.log('ShoppingCartComponent: user clicked on increment-item button..... index = '+index);
    this.logger.log(this.cart[index]); // DEBUG
    
    if((!this.authService.isLoggedIn() && Number(this.cart[index]['quantità']) < Number(this.cart[index]['quantita_disponibile']))){
      // Utente NON loggato
      this.doIncrementItemPv(index);
    }else if(this.authService.isLoggedIn() && !this.cart[index].choice_id && 
      Number(this.cart[index]['quantità']) < Number(this.cart[index]['prodotto']['quantita_disponibile'])){
      // Utente loggato && prodotto SENZA OPZIONI
      this.doIncrementItemPv(index);
    }else if(this.authService.isLoggedIn() && this.cart[index].choice_id){
      // Utente loggato && prodotto CON OPZIONI
      for(let opt of this.cart[index].prodotto.options){
        for(let choice of opt.choices){
          if(this.cart[index].choice_id==choice.id){
            if(Number(this.cart[index]['quantità']) < Number(choice.quantita_disponibile)){
              this.doIncrementItemPv(index);
            }else{
              this.logger.log('ShoppingCartComponent: Quantità non disponibile');
              this.toastLauncher('Quantità di prodotto richiesta non disponibile!');
            }
            return; // Rompo entrambi i cicli uscendo dalla funzione
          }
        }
      }
    }else{
      this.logger.log('ShoppingCartComponent: Quantità non disponibile');
      this.toastLauncher('Quantità di prodotto richiesta non disponibile!');
    }
  }

  private doIncrementItemPv(index: number){
    this.shoppingCartEdited = true;
    this.cart[index]['quantità'] = Number(this.cart[index]['quantità']) + 1;
    this.cart[index]['costo_item'] = this.roundTo(this.cart[index]['quantità'] * this.cart[index]['prezzo_vetrina'],1);
    // aggiornamento della matrice dei costi di trasporto per venditore
    if(this.authService.isLoggedIn()){
      this.shippingCosts[this.cart[index]['prodotto']['venditore']['id']] = Number(this.cart[index]['prezzo_trasporto']);
    }else{
      this.shippingCosts[this.cart[index]['venditoreId']] = Number(this.cart[index]['prezzo_trasporto']);
    }
    this.computeTotalThroughPv(false);
  }

  async toastLauncher(message: string){
    const toast = await this.toastController.create({
      message: message,
      duration: 2000
    });
    toast.present();
  }

  decrementItemPv(index: number){
    this.logger.log('ShoppingCartComponent: user clicked on decrement-item button..... index = '+index);
    
    if(Number(this.cart[index]['quantità']) - 1 > 0){
      this.shoppingCartEdited = true;
      this.cart[index]['quantità'] = Number(this.cart[index]['quantità']) - 1;
      this.cart[index]['costo_item'] = this.roundTo(this.cart[index]['quantità'] * this.cart[index]['prezzo_vetrina'],1);
      // aggiornamento della matrice dei costi trasporto x venditore
      if(this.authService.isLoggedIn()){
        this.shippingCosts[this.cart[index]['prodotto']['venditore']['id']] = Number(this.cart[index]['prezzo_trasporto']);
      }else{
        this.shippingCosts[this.cart[index]['venditoreId']] = Number(this.cart[index]['prezzo_trasporto']);
      }
      this.computeTotalThroughPv(false);
    }else if(Number(this.cart[index]['quantità']) - 1 == 0){
      this.CreatePopover(null, index);
    }
  }

  toUserProfile(){
    this.logger.log("Navigation from Home to User Profile...");
    this.router.navigateByUrl("/myprofile");
  }

  async ionViewWillLeave(){
    this.logger.log('ShoppingCartComponent: -----> ionViewWillLeave starts <-----');
    this.logger.log('isLoggedIn() -> '+this.authService.isLoggedIn());
    if(this.shoppingCartEdited && this.authService.isLoggedIn()){
      // DOC: Valuto se aggiornare il token prima di richiedere i dati della pagina al backend
      // Se il token è scaduto, allora eseguo un refresh e poi chiedo i dati..
      if(this.authService.hasToRefreshBasicAuth()){
        this.authService.refreshBasicAuthToken().then(
          go => {
            this.updatingSC();
          }
        )
      }else{
        // ..Altrimenti chiedo i dati senza eseguire il refresh
        this.updatingSC();
      }
    }else if(this.shoppingCartEdited && !this.authService.isLoggedIn()){
      this.logger.log('ShoppingCartComponent: ..writing the new shopping cart to the local storage');
      this.removeLocalShoppingCartItems();
      this.shoppingCartManagerService.localCart_storageKeys('ShoppingCartComponent');
      
      for(let c of this.cart){
        // TODO: (Se i test del carrello locale vanno male) Valutare modifica della key in pagina prodotti anche qui
        // TODO: ..ma dovrebbe andare, perchè semplicemente prendi la chiave presente tagliando solo la parte iniziale
        if(c['key'] && c['name'] && c['image'] && c['quantità'] && c['costo'] && c['prezzo_vetrina'] && c['prezzo_trasporto'] && c['venditoreId'] && c['quantita_disponibile']){
          this.shoppingCartManagerService.localCart_putObjectIntoSC(c['key'].substring(13,c['key'].length),c['name'],c['image'],c['quantità'],c['costo'],c['prezzo_vetrina'], 
          c['prezzo_trasporto'], c['venditoreId'], c['venditore_nome'], c['quantita_disponibile'], c['options']);
        }
      }
      this.shoppingCartManagerService.localCart_storageKeys('ShoppingCartComponent');
    }

    // Chiudo i vari popover prima di lasciare la pagina (pop = popover aperto in quel momento -> è al massimo UNO)
    if(this.pop){
      const popover = await this.popover.getTop();
      if(popover){
        await popover.dismiss();
        this.logger.log('Popover dismissed');
      }
    }
  }

  private updatingSC(){
    this.onUpdateSC().subscribe(
      posts => {
        this.logger.log('Badge del carrello = '+posts);

        this.shoppingCartManagerService.getShoppingCartContent('ShoppingCartComponent');
      },
      error => {
        this.logger.log(error);
      });
  }

  private onUpdateSC(){
    this.logger.log('ShoppingCartComponent: ..sending the new shopping cart to the server');
    var cart_toBePosted = [];
    for(let c of this.cart){
      let new_item: NewShoppingCartItem = {
        quantità: c.quantità,
        prodotto: c.prodotto.id,
        choice_id: c.choice_id
      }
      this.logger.log(new_item.quantità);
      this.logger.log(new_item.prodotto);
      this.logger.log(new_item.choice_id);
      cart_toBePosted.push(new_item);
    }
    if(cart_toBePosted.length>0){
      return this.onNewShoppingCartPOST(cart_toBePosted);
    }else if(cart_toBePosted.length==0){
      this.logger.log('ShoppingCartComponent: ..sending a void shopping cart to the server (maybe it has been reduced...)')
      return this.onNewShoppingCartPOST(cart_toBePosted);
    }else{
      return new Observable<number>(observer => observer.complete());
    }
  }

  onNewShoppingCartPOST(cart: NewShoppingCartItem[]){
    this.logger.log('ShoppingCartComponent: ..calling POST service with new cart = '+cart);
    
    return this.shoppingCartManagerService.updateShoppingCart('ShoppingCartComponent', cart);
  }

  shoppingCartReducer(){
    let temp_cart = [];
    for(let i of this.cart){
      if(i != null){
        temp_cart.push(i);
      }else{
        // do nothing
        this.shoppingCartEdited = true;
      }
    }
    if(temp_cart.length==0){
      this.hasCartItems = false;
    }
    this.cart = temp_cart;
  }

  removeLocalShoppingCartItems(){
    this.logger.log('ShoppingCartComponent: ..removing local shopping cart items');
    for(let c of this.cart){
      this.shoppingCartManagerService.localCart_removeItem(c['key']);
    }
  }

  async storageValues() {
    this.subtotal = 0;
    this.total = 0;
    this.shippingCosts = {};
    this.costPerVendor = {};
    this.shippingCostsSize = 0;
    this.shippingCostTotal = 0;
    this.Cs = 0;
    this.hasShippingCosts = false;
    this.nElementi = 0;
    var pricesSum = 0;

    this.isFetching = true;
    this.shoppingCartManagerService.localCart_storageValues('ShoppingCartComponent').then(
      res => {
        this.isFetching = false;
        this.localStorageKeys = res;

        var index = 0;
        for(let key of this.localStorageKeys){
          this.hasCartItems = true;
          const temp_index = index;
          this.shoppingCartManagerService.localCart_getObject(key).then(
            value => { 
              this.cart[temp_index] = value;
              this.cart[temp_index]['key'] = key;
              //this.logger.log('key = '+this.cart[temp_index]['key']);   // DEBUG
              //this.logger.log('Got storage value (obj) with name: '+value['name']);   // DEBUG
              //this.logger.log('Got storage value (obj) with image: '+value['image']);   // DEBUG
              //this.logger.log('Got storage value (obj) with amount: '+value['quantità']);   // DEBUG
              //this.logger.log('Got storage value (obj) with Pv: '+value['prezzo_vetrina']);   // DEBUG
              //this.logger.log('Got storage value (obj) with venditore: '+value['venditore_nome']);   // DEBUG
              //this.logger.log('Got storage value (obj) with costo: '+value['costo']);   // DEBUG

              this.nElementi = this.nElementi + Number(value['quantità']);
              this.subtotal = this.subtotal + Number(value['prezzo_vetrina'] * value['quantità']);
              pricesSum = pricesSum + value['prezzo_vetrina'] * value['quantità'];
              // Aggiorno la matrice dei costi per singolo venditore
              if(!this.costPerVendor[value['venditoreId']]){
                this.costPerVendor[value['venditoreId']] = Number(value['prezzo_vetrina'] * value['quantità']);
              }else{
                this.costPerVendor[value['venditoreId']] = this.costPerVendor[value['venditoreId']] + Number(value['prezzo_vetrina'] * value['quantità']);
              }
              this.logger.log('ID Venditore: '+value['venditoreId']);  // DEBUG

              // Aggiorno la matrice dei costi di spedizione
              if(!this.shippingCosts[value['venditoreId']] || this.shippingCosts[value['venditoreId']]<value['prezzo_trasporto']){
                this.shippingCosts[value['venditoreId']] = Number(value['prezzo_trasporto']);
                this.logger.log('Nuovo costo T = '+this.shippingCosts[value['venditoreId']]+' per il venditore: '+value['venditoreId']);
              }

              if(!value['costo_item'] && value['quantità'] && value['prezzo_vetrina']){
                // Se non esiste il parziale per l'item (caso iniziale), allora lo creo
                value['costo_item'] = this.roundTo(value['quantità'] * value['prezzo_vetrina'],1);
              }

              this.logger.log(this.costPerVendor);   // DEBUG

              this.logger.log('pricesSum = '+pricesSum);
              this.logger.log('Subtotal = '+this.subtotal);
              for(let Tmax_key in this.shippingCosts){
                if(this.costPerVendor[Tmax_key]<this.SPEDIZIONE_GRATUITA){
                  // Solo se la spesa per venditore è minore della soglia di SPEDIZ GRATUITA, allora aggiungo il T max per quel venditore
                  this.logger.log('INCLUSO il costo di trasporto: '+this.shippingCosts[Tmax_key]+' per venditore = '+Tmax_key);
                  this.shippingCostTotal= this.shippingCostTotal + this.shippingCosts[Tmax_key];
                  this.shippingCostsSize = this.shippingCostsSize + 1;
                }else{
                  this.logger.log('Per il venditore = '+Tmax_key+' non ho incluso costi');
                }
              }

              if(this.shippingCostsSize>0){
                this.hasShippingCosts = true;
              }else{
                this.hasShippingCosts = false;
              }
              this.logger.log('Update del CarrelloLocale: length del dict dei costi di spedizione = '+this.shippingCostsSize);
              this.logger.log('Update del CarrelloLocale: costo di spedizione totale = '+this.shippingCostTotal);
              if(this.subtotal>0){
                this.Cs = this.STRIPE_MULTIPLIER * ( pricesSum + this.shippingCostTotal ) + this.STRIPE_CONSTANT;
              }
              this.logger.log('Update del CarrelloLocale: costo di transazione totale = '+this.Cs);
              // Cs not used in total computation
              this.total = this.roundTo(this.roundTo(this.subtotal,1) + this.shippingCostTotal,1);
              this.logger.log('Update del CarrelloLocale: Total = '+this.total);

              // EXIT CODE -> SE index è l'ultimo
              if(temp_index==this.localStorageKeys.length-1){
                this.computeTotalThroughPv(false);
              }
            }
          );
          index = index + 1;
        }
        
      });

  }
  
  async CreatePopover(ev: any, index: number) {
    this.pop = await this.popover.create({
      component: SCDeleteItemPopover,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
      componentProps: {}
    });

    this.pop.onDidDismiss().then(result => {
      if(result!=null){
         this.logger.log('ShoppingCartComponent: popover closed with boolean = '+result.data);
         if(result.data){
           // Utente ha premuto YES, vuole eliminare l'item
           this.shoppingCartEdited = true;
           if(!this.authService.isLoggedIn()){
            // Devo rimuovere l'item dal carrello locale
            this.shoppingCartManagerService.localCart_removeItem(this.cart[index]['key']);
            // Pulizia dizionario per utente NON loggato
            delete this.shippingCosts[this.cart[index]['venditoreId']];
            this.cutCostPerVendor(index);
           }else{
            // Pulizia dizionario per utente LOGGATO
            delete this.shippingCosts[this.cart[index].prodotto.venditore.id];
            this.cutCostPerVendor(index);
           }
           this.cart[index] = null;
           this.shoppingCartReducer();
           this.computeTotalThroughPv(false);
         }else{
           // Utente non ha premuto YES, tutto rimane come prima
         }
      }
    });

    return await this.pop.present();
  }

  cutCostPerVendor(index: number){
    if(!this.authService.isLoggedIn()){
      if(this.costPerVendor[this.cart[index]['venditoreId']]){
        if(this.costPerVendor[this.cart[index]['venditoreId']] - Number(this.cart[index]['prezzo_vetrina']*this.cart[index]['quantità']) >= 0){
          this.costPerVendor[this.cart[index]['venditoreId']] = this.costPerVendor[this.cart[index]['venditoreId']] - Number(this.cart[index]['prezzo_vetrina']*this.cart[index]['quantità']);
        }else{
          this.costPerVendor[this.cart[index]['venditoreId']] = 0;
        }
      }else{
        delete this.costPerVendor[this.cart[index]['venditoreId']];
      }
    }else{
      if(this.costPerVendor[this.cart[index].prodotto.venditore.id]){
        if(this.costPerVendor[this.cart[index].prodotto.venditore.id] - Number(this.cart[index]['prezzo_vetrina']*this.cart[index]['quantità']) >= 0){
          this.costPerVendor[this.cart[index].prodotto.venditore.id] = this.costPerVendor[this.cart[index].prodotto.venditore.id] - Number(this.cart[index]['prezzo_vetrina']*this.cart[index]['quantità']);
        }else{
          this.costPerVendor[this.cart[index].prodotto.venditore.id] = 0;
        }
      }else{
        delete this.costPerVendor[this.cart[index].prodotto.venditore.id];
      }
    }
  }

  onReloadShoppingCart(){
    this.logger.log('ShoppingCartComponent: onReloadShoppingCart() called');
    this.ngOnInit();
    this.ionViewWillEnter();
  }

  toCheckoutPage(){
    if(this.cart!=null && this.cart.length>0){
      this.logger.log('ShoppingCartComponent: toCheckoutPage() called');
      if(this.authService.isLoggedIn() && !this.userService.userIsVendor()){
        if(this.cart[0].costo!=this.total){
          // Devo aggiornare il carrello remoto prima di andare oltre...
          this.logger.log('Aggiornamento carrello remoto prima del checkout');
          this.isFetching = true;
          // DOC: Valuto se aggiornare il token prima di richiedere i dati della pagina al backend
          // Se il token è scaduto, allora eseguo un refresh e poi chiedo i dati..
          if(this.authService.hasToRefreshBasicAuth()){
            this.authService.refreshBasicAuthToken().then(
              go => {
                this.updateSCpreCheckoutPage();
              }
            )
          }else{
            // ..Altrimenti chiedo i dati senza eseguire il refresh
            this.updateSCpreCheckoutPage();
          }
        }else{
          this.logger.log('..direttamente al checkout');
          this.toCheckout();
        }
      }else if(!this.authService.isLoggedIn()){
        this.router.navigate(['/login']); 
      }else if(this.authService.isLoggedIn() && this.userService.userIsVendor()){
        this.router.navigate(['404']);  
      }else{
        this.logger.log('ShoppingCartComponent: toCheckoutPage().. situazione non gestita');
      }
    }else{
      this.logger.log('ShoppingCartComponent: toCheckoutPage() called.... BUT no items in cart!')
    }
  }

  private updateSCpreCheckoutPage(){
    this.onUpdateSC().subscribe(
      posts => {
        this.logger.log('Carrello aggiornato e restituito un cart badge = '+posts);
        this.isFetching = false;
        this.shoppingCartEdited = false;
        this.cart[0].costo = ''+this.total;
        this.toCheckout();
      },
      error => {
        this.logger.log(error);
        this.isFetching = false;
      }
    )
  }

  private toCheckout(){
    let navigationExtras: NavigationExtras = {
      state: {
        from: 'shopping-cart',
        cart: JSON.stringify(this.cart)
      }
    };
    this.router.navigate(['/checkout'], navigationExtras); 
  }

  toProductDetail(id: string){
    this.logger.log('ShoppingCartComponent: toProductDetail() called with product ID = '+id);
    this.router.navigate(["/products/" + id + "/detail"]);
  }

  toVendor(index: number){
    this.logger.log('ShoppingCartComponent: toVendor() called with index = '+index);
    if(this.authService.isLoggedIn()){
      this.router.navigate(["vendors/vendor/" + this.cart[index].prodotto.venditore.id]);
    }else{
      this.router.navigate(["vendors/vendor/" + this.cart[index]['venditoreId']]);
    }
  }

  round(num: number){
    if(num>=10){
      // Se il numero è 10 o maggiore di 10 allora approssimo agli interi
      return this.roundTo(num,0);
    }else if(num>0 && num<10){
      // Se il numero è minore di 10 allora approssimo alla prima cifra decimale 
      return this.roundTo(num,1);
    }else{
      // Se è minore o uguale a 0 allora ritorno 0
      return 0;
    }
  }
  roundTo(num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  }
}