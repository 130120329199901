import { Component, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { EventEmitter } from '@angular/core'
import { CRDeleteItemPopover } from 'src/app/checkout/checkout-riepilogo/checkout-riepilogo-popover/checkout-riepilogo-popover.component';
import { ConstantUtilsService } from 'src/app/shared/services/constant-utils.service';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-checkout-riepilogo',
  templateUrl: './checkout-riepilogo.component.html',
  styleUrls: ['./checkout-riepilogo.component.scss'],
})
export class CheckoutRiepilogoComponent implements OnInit {

  @Input() items: any[] = [];
  itemsEdited: boolean = false;
  orderEdited: boolean = false;
  
  @Output() total_emitter = new EventEmitter<number>();
  @Output() trasporto_emitter = new EventEmitter<number>();
  private total: number;
  pop: any;

  constructor(private popover: PopoverController, private router: Router, public constantUtils: ConstantUtilsService,
    private logger: NGXLogger) { }

  ngOnInit() {
    this.logger.log('ITEMS nel riepilogo: '+JSON.stringify(this.items)); // DEBUG
    this.processChoices();
  }

  private processChoices(){
    for(let item of this.items){
      this.logger.log('choice_id del item con ID='+item.id+': '+item.choice_id); // DEBUG
      if(item.choice_id && item.prodotto.options){
        for(let opt of item.prodotto.options){
          for(let choice of opt.choices){
            this.logger.log('Ciclata la choice con ID='+choice.id); // DEBUG
            if(choice.id==item.choice_id){
              item['option_titolo'] = opt.titolo;
              item['choice_descrizione'] = choice.descrizione;
              this.logger.log('Aggiunti i campi per la choice con ID='+choice.id); // DEBUG
            }
          }
        }
      }
    }
  }

  deleteItem(index: number){
    this.logger.log('CheckoutRiepilogo: user clicked on remove-item button..... index = '+index);
    this.CreatePopover(null, index);
  }

  async CreatePopover(ev: any, index: number) {
    this.pop = await this.popover.create({
      component: CRDeleteItemPopover,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
      componentProps: {}
    });

    this.pop.onDidDismiss().then(result => {
      if(result!=null){
         this.logger.log('CheckoutRiepilogo: popover closed with boolean = '+result.data);
         if(result.data){
           // Utente ha premuto YES, vuole eliminare l'item
           this.orderEdited = true;
           this.items[index] = null;
           // TODO: Richiesta al server per eliminare il prodotto dal carrello (su branch main) -> POI l'itemsReducer
           this.itemsReducer();
           //this.computeTotalThroughPv();
         }else{
           // Utente non ha premuto YES, tutto rimane come prima
         }
      }
    });

    return await this.pop.present();
  }

  async ngOnDestroy(){
    this.logger.log('CheckoutRiepilogo: ----- ngOnDestroy()  ----- ');
    // Chiudo i vari popover prima di lasciare la pagina (pop = popover aperto in quel momento -> è al massimo UNO)
    if(this.pop){
      const popover = await this.popover.getTop();
      if(popover){
        await popover.dismiss();
        this.logger.log('Popover dismissed');
      }
    }
  }

  itemsReducer(){
    let temp_cart = [];
    for(let i of this.items){
      if(i != null){
        temp_cart.push(i);
      }else{
        // do nothing
        this.itemsEdited = true;
      }
    }
    this.items = temp_cart;
    if(this.items!=null && this.items.length==0){
      // Items became 0, no more items in checkout page 
      this.router.navigateByUrl('shopping-cart');
    }
  }

  roundTo(num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  }

}
