<ion-list style="padding-top: 0px; padding-bottom: 0px;">
  <ion-list-header color="primary">Gestisci Prodotto</ion-list-header>
  <ion-item button (click)="openEditProductPage()">Modifica Prodotto</ion-item>
  <ion-item button (click)="openEditTAGsPage()">Modifica i TAG</ion-item>
  <ion-item button (click)="openProductSwipe()">Apri Scheda Prodotto</ion-item>
  <ion-item button (click)="openProductDetail()">Apri Dettaglio Prodotto</ion-item>
  <ion-item *ngIf="product.options.length==0 && product.quantita_disponibile > 0" button (click)="setAsUnavailable()">
    Elimina Prodotto
  </ion-item>
  <ion-item *ngIf="product.options.length>0" button (click)="setProdWithOptionsAsUnavailable()">
    Elimina Prodotto
  </ion-item>
  <ion-item lines="none" detail="false" color="secondary" button (click)="this.dismissPopover()">Chiudi</ion-item>
</ion-list>