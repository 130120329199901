import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import { of, Subject, throwError } from 'rxjs';
import { UserService } from './User.service';
import { catchError, map } from 'rxjs/operators';
import { VendorReviewsListResponse } from '../models/Recensioni.models';
import { ConstantUtilsService } from './constant-utils.service';
import { NGXLogger } from 'ngx-logger';
import { UpdateChoiceObj, VendorProductOptionsListResponse } from '../models/Opzioni.models';

@Injectable({ providedIn: 'root' })
export class VendorProductOptionsService {

  constructor(
    private constantUtils: ConstantUtilsService,
    private http: HttpClient, private logger: NGXLogger) {}

  private defaultUrl: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/vendors/products/options');
  private choiceUpdateBaseUrl: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/vendors/products/options/choices/');

  error = new Subject<string>();

  fetchOptions(){
    return this.http
    .get<VendorProductOptionsListResponse>(
      this.defaultUrl,
      {
        headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
        responseType: 'json'
      }
    )
    .pipe(
      map((responseData:VendorProductOptionsListResponse) => {
        return responseData;
      }),
      catchError(errorRes => {
        // Send to analytics server
        return throwError(errorRes);
      })
    );
  }


  updateProductChoice(choiceID: number, newChoice){
    const httpHeaders = new HttpHeaders({ 
      'Content-Type' : 'application/json',
      // 'Authorization':"Bearer "+ this.userTokenData.access_token
    });

    this.logger.log('updateProductChoice: sending the PUT request.....');   // DEBUG

    return this.http.put<UpdateChoiceObj>(this.choiceUpdateBaseUrl+choiceID+'/', newChoice, 
      {headers: httpHeaders, responseType: 'json'}).toPromise().then(
      (responseData) => {
        this.logger.log('updateProductChoice: responseData = '+JSON.stringify(responseData)); // DEBUG
        return responseData;
      },
      errorRes => {
        return errorRes;
      });
    }
}