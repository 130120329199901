import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonContent, IonInfiniteScroll, IonSearchbar, ModalController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Prodotti, ProdottoItem } from 'src/app/shared/models/Prodotti.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AutoCompleteService } from 'src/app/shared/services/autocomplete.service';
import { GetProdottiService } from 'src/app/shared/services/getProdotti.service';
import { ProdottiParentComponent } from '../prodotti-parent.interface';
import { UserService } from 'src/app/shared/services/User.service';
import { GrigliaProdottiComponent } from '../griglia-prodotti/griglia-prodotti.component';
import { ConstantUtilsService } from 'src/app/shared/services/constant-utils.service';
import { ShoppingCartManagerService } from 'src/app/shared/services/shopping-cart-manager.service';
import { GetCategorieService } from 'src/app/shared/services/getRootCategories.service';
import { CategoriaItem, Categorie } from 'src/app/shared/models/Categorie.model';
import { GetProdottiFilteredService } from 'src/app/shared/services/getProdottiFiltered.service';
import { NGXLogger } from 'ngx-logger';


@Component({
  selector: 'app-pagina-prodotti',
  templateUrl: './pagina-prodotti.component.html',
  styleUrls: ['./pagina-prodotti.component.scss'],
})
export class PaginaProdottiComponent implements OnInit, ProdottiParentComponent {
  @ViewChild(IonContent, {static: false}) private content: IonContent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonSearchbar) searchbar: IonSearchbar;
  @ViewChild(GrigliaProdottiComponent) griglia_prodotti: GrigliaProdottiComponent;
  page_title: string = 'Prodotti';
  ZERO_CONSTANT: Number = Number(0);
  c_next: string; // next param per l'API categorie
  next: string;
  next_index: number = 0;
  firstCall: boolean = true;
  resArray: Prodotti;
  prodotti: ProdottoItem[];
  hasProdotti: boolean = false;
  autocompleteResult: string;
  suggestions: string[];
  hasSuggestion: boolean = false;
  isFetching = true;
  error = null;
  private errorSub: Subscription;
  showFilterBtn: boolean = true;
  categories: CategoriaItem[] = null;
  resCategories: Categorie;

  choiceSorting: string = 'Nessuno';
  choiceCategory: string = "Nessuna";
  filterMode: boolean = false;
  media_recensioni_filter: number = 0;
  min_price: number = 0;
  max_price: number = 0;
  min_price_input: string;
  max_price_input: string;
  hasActiveFilters: boolean = false;

  type: string; // navigation extra
  id: string; // navigation extra
  name: string; // navigation extra
  incoming_search: string; // navigation extra
  private order_key: string = null;
  private modal_index: string = null;
  private ordering: string = null;

  searchInput: string;
  noResults: boolean = false;

  cartBadge: Number;
  shoppingCartResArray: any = [];

  threshold_one: number = 0.5;
  threshold_two: number = 1.5;
  threshold_three: number = 2.5;
  threshold_four: number = 3.5;
  threshold_five: number = 4.5;

  private innerWidth: any;
  private SCREEN_SIZE_XS_SM: number = 576;
  private SCREEN_SIZE_SM_MD: number = 768;
  smallScreen: boolean = false;

  email: string = 'mailto: assistenza@emplacestore.it?Subject=Suggerimento nuovo prodotto';

  constructor(
    private activatedRoute: ActivatedRoute, 
    private authService: AuthService, 
    private autocompleteService: AutoCompleteService,
    private constantUtils: ConstantUtilsService,
    private getProdottiService: GetProdottiService, 
    public platform: Platform, 
    private router: Router, private logger: NGXLogger,
    private getCategoriesService: GetCategorieService,
    public shoppingCartManagerService: ShoppingCartManagerService,
    public userService: UserService,
    private getProdottiFilteredService: GetProdottiFilteredService) {
        // Recupero l'extra (parametro del nome) dalla pagina sorgente
        this.activatedRoute.queryParams.subscribe(params => {
          if (this.router.getCurrentNavigation().extras.state) {
            this.name= this.router.getCurrentNavigation().extras.state.name;
            this.incoming_search = this.router.getCurrentNavigation().extras.state.search;
            if (this.router.getCurrentNavigation().extras.state.type) {
              this.order_key = this.router.getCurrentNavigation().extras.state.type;
              this.modal_index = this.router.getCurrentNavigation().extras.state.index;
              this.ordering = this.router.getCurrentNavigation().extras.state.ordering;
            }
          }
        });
      }
  private PRODUCTS_CATEGORY_URL: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/category-products/');

  
  ngOnInit() {
    this.logger.log("PaginaProdottiComponent: --------------->   onInit   <-----------------");
    this.type = this.activatedRoute.snapshot.paramMap.get('type');
    this.logger.log("PaginaProdottiComponent: type parameter = "+this.type);
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.logger.log("PaginaProdottiComponent: id parameter = "+this.id);

    if(this.name!=null){
      this.page_title = this.name;
    }

    if(this.incoming_search){
      this.logger.log("PaginaProdottiComponent: incoming search query = "+this.incoming_search);
      // Managing search with query = incoming search words
      this.getProducts(null,{'query': this.incoming_search, 'order_key': 'media_recensioni', 'ordering': 'desc'});
    }else if(this.order_key==null){
      const pageParams = this.setParams(this.type, this.id, null, null);
      this.getProducts(null, pageParams);
    }else{
      // Provenienza da sezione-home
      var pageParams = null;
      if(this.ordering){
        pageParams = this.setParams('sezione-home', this.id, this.order_key, this.ordering);
      }else{
        pageParams = this.setParams('sezione-home', this.id, this.order_key, null);
      }
      this.getProducts(null, pageParams);
    }
  }

  ionViewWillEnter(){
    this.logger.log("PaginaProdottiComponent: ionViewWillEnter");
    this.type = this.activatedRoute.snapshot.paramMap.get('type');
    this.logger.log("PaginaProdottiComponent: type parameter = "+this.type);
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.logger.log("PaginaProdottiComponent: id parameter = "+this.id);

    this.onResize(null);

    // When the View is loaded, check if we reached the end of the screen - to understand if we need to load other products
    this.checkIfBottomReached();

    this.initializeShoppingCartBadge();

    this.getCategories(null);
  }

  // Tiene conto dei cambi di screen size
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    //this.logger.log('HomePage: cambio di screen size -> '+this.innerWidth);  // DEBUG
    this.screenManager();
  }

  private screenManager(){
    // Gestisco il cambio di dimensione dello schermo 
    if(this.innerWidth<this.SCREEN_SIZE_SM_MD){
      this.smallScreen = true;
    }else{
      this.smallScreen = false;
    }
  }

  ionViewWillLeave(){
    this.logger.log('PaginaProdotti: ----- ionViewWillLeave  ----- ');
  }

  getCategories(next: string){
    this.logger.log('PaginaProdotti: getCategories called');
    this.getCategoriesService.fetchCategories(next).subscribe(
      posts => {
        this.logger.log(posts);
        this.resCategories = <Categorie>posts;
        if(this.categories!=null){
          this.categories = this.categories.concat(this.resCategories.results);
        }else{
          this.categories = this.resCategories.results;
        }

        this.c_next = this.resCategories.next;
        if(this.c_next){
          // Caso in cui ce ne sono altre
          this.getCategories(this.c_next);
        }
      }, error => {
        this.logger.log(error);
        this.categories = null;
      }
    )
  }

  loadData(event) {
    if(!this.isFetching){
      this.getProducts(event, null);
    }
    if(event){
      event.target.complete();
    }
  }

  moreDataToLoad(){
    return this.next != null || this.firstCall;
  }

  fetchingProducts(){
    return this.isFetching;
  }

  getProdotti(){
    return this.prodotti;
  }

  getProducts(event, requestParams){
    this.logger.log('PaginaProdottiComponent: getProducts <------------');

    if(this.moreDataToLoad()){
      this.isFetching = true;
      this.firstCall = false;
      this.errorSub = this.getProdottiService.error.subscribe(errorMessage => {
        this.error = errorMessage;
      });
      
      this.errorSub = this.getProdottiService.error.subscribe(errorMessage => {
        this.error = errorMessage;
      });
      
      this.getProdottiService.fetchProductsByUrlAndParams(this.next, requestParams).subscribe(
        posts => {
          this.isFetching = false;
          this.resArray = <Prodotti>posts;
          this.logger.log('PaginaProdottiComponent: GET retrieved something...');
          this.logger.log(this.resArray);
          this.next = this.resArray.next;
          this.logger.log('PaginaProdottiComponent: next parameter');
          this.logger.log(this.next);
          this.next_index = this.next_index + 10;
          this.logger.log('PaginaProdottiComponent: GET results:');
          if(this.prodotti!=null){
            // after the first time...
            this.prodotti = this.prodotti.concat(this.resArray['results']);
            //this.virtualScroll.trackBy(this.next_index, this.prodotti[this.next_index]);
          }else{
            // the first time...
            this.prodotti = this.resArray['results']; 
          }
          this.logger.log(this.prodotti);
          if(this.prodotti.length>0){
            this.hasProdotti = true;
            this.noResults = false;

            // Check if we came from sezione-home.. We should open a swipe in this case
            this.logger.log('PaginaProdotti: order_key = '+this.order_key);
            this.logger.log('PaginaProdotti: modal_index = '+this.modal_index);
            if(this.order_key!=null && this.modal_index!=null){
              this.delay(700).then(
                resolve => {
                  this.logger.log('PaginaProdotti: aprendo lo swipe causa sezione-home...');
                  this.griglia_prodotti.openSwipeTo(this.modal_index);
                }
              )
            }
            this.order_key = null;

            // Check if we reached the end of the screen - to understand if we need to load other products
            this.checkIfBottomReached();
          }else{
            this.noResults = true;
            this.hasProdotti = true;
          }
        },
        error => {
          this.isFetching = false;
          this.hasProdotti = false;
          this.error = error.message;
          this.logger.log('PaginaProdottiComponent: GET..... an error occurred');
        }
      );
    }
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  checkIfBottomReached(){
    // this.logger.log("checkIfBottomReached start")
    if(!this.moreDataToLoad() || this.isFetching){
      // this.logger.log("checkIfBottomReached. exiting", this.moreDataToLoad(), this.isFetching)
      return;
    }
    this.checkForScrollbar().then(bottomReached => {
      // this.logger.log("checkIfBottomReached - scrollBarVisible=", bottomReached)
      if (!bottomReached && !this.isFetching) {
        this.logger.log("Screen is not filled with products. Loading some more.")
        this.getProducts(null, null);
      }
    });
  }

  async checkForScrollbar() {
    // This method is used to fix a bug related to the InfiniteScroll object,
    // that does not work if the content (meaning, the grid of products) does not fill the screen
    // (this bug only occurs on big screens with a high resolution for this page)
    const scrollElement = await this.content.getScrollElement()
    // this.logger.log("checkForScrollbar", scrollElement.scrollHeight, scrollElement.clientHeight)
    
    // If the scrollHeight is 0, the view is not yet loaded. Don't do anything.
    if(scrollElement.scrollHeight === 0){
      return true;
    }
    // this.logger.log("Returning", scrollElement.scrollHeight > scrollElement.clientHeight)
    return scrollElement.scrollHeight > scrollElement.clientHeight;
  }

  onHandleError() {
    this.error = null;
  }

  ngOnDestroy() {
    this.errorSub.unsubscribe();
  }

  onInput($event){
    this.logger.log('PaginaProdottiComponent: SEARCH ------> onInput');
    this.searchbar.getInputElement().then(input => {
      var inputValue = input.value; 
      this.searchInput = inputValue;
      if(this.searchInput!=null && this.searchInput!=' ' && this.searchInput.length>1){
        this.autocompleteRequest(this.searchInput);
      }else{
        this.hasSuggestion = false;
      }
      this.logger.log('searchInput: '+this.searchInput);
    });
  }

  onCancel($event){
    this.logger.log('PaginaProdottiComponent: SEARCH - onCancel');
    this.searchInput = '';
    this.logger.log('searchInput: '+this.searchInput);
    this.hasSuggestion = false; // Closing suggestions list
  }

  onSearch($event){   
    if($event && $event.key=='Enter'){  
      this.logger.log('PaginaProdottiComponent: SEARCH - onSearch');
      this.logger.log('searchInput: '+this.searchInput);
      
      this.startSearch();
    }
  }

  startSearch(){
    this.logger.log('ricerca iniziata con searchInput = '+this.searchInput);
    if(this.searchInput!=null){
      this.prodotti = [];
      this.firstCall = true;
      this.hasSuggestion = false;

      if(this.type=='category'){
        this.next = this.PRODUCTS_CATEGORY_URL+this.id;
      }else{
        this.next = null;
      }
      
      if(this.searchInput!=''){
        this.logger.log('searching for: '+this.searchInput);
        this.getProducts(null,{'query': this.searchInput, 'order_key': 'media_recensioni', 'ordering': 'desc'});
      }else{
        this.logger.log('searching for all products...');
        this.getProducts(null,{'order_key': 'media_recensioni', 'ordering': 'desc'});
        this.noResults = false;
      }
    }
  }

  private setParams(type: string, id: string, order_key: string, ordering: string): any {
    this.logger.log("PaginaProdottiComponent: setParams ---> START with type = "+type);
    if(type==null){
      this.logger.log("PaginaProdottiComponent: setParams ---> END with default params");
      return {'order_key': 'media_recensioni', 'ordering': 'desc'};
    }else if(type=='category'){
      this.logger.log("PaginaProdottiComponent: setParams ---> END with products x category params");
      this.next = this.PRODUCTS_CATEGORY_URL+id;
      return {'order_key': 'media_recensioni', 'ordering': 'desc'};
    }else if(type=='sezione-home' && ordering){
      this.logger.log("PaginaProdottiComponent: setParams ---> coming from sezione-home ---> END with order_key = "+order_key);
      return {'order_key': order_key, 'ordering': ordering}
    }else if(type=='sezione-home'){
      this.logger.log("PaginaProdottiComponent: setParams ---> coming from sezione-home ---> END with order_key = "+order_key);
      return {'order_key': order_key, 'ordering': 'desc'}
    }else{
      this.logger.log("PaginaProdottiComponent: setParams ---> END with default params");
      return {'order_key': 'media_recensioni', 'ordering': 'desc'};
    }
  }

  autocompleteRequest(input: string){
    this.logger.log("PaginaProdottiComponent: autocompleteRequest started with input = "+input);
    var requestParams = {'term':input};
    this.autocompleteResult = null;
    this.autocompleteService.fetchProductsByUrlAndParams(null, requestParams).subscribe(
      posts => {
        this.autocompleteResult = ""+posts;
        this.logger.log('PaginaProdottiComponent: autocompleteRequest ended with result = '+this.autocompleteResult);
        if(this.autocompleteResult!=null && this.autocompleteResult!='[]'){
          this.hasSuggestion = true;
          var result_cut = this.autocompleteResult.substring(1,this.autocompleteResult.length-1);
          this.suggestions = result_cut.split(", ");
          if(this.suggestions.length>7){
            this.suggestions = this.suggestions.slice(0,6);
          }
        }else{
          this.hasSuggestion = false;
        }
      },
      error => {
        this.error = error.message;
        this.logger.log('PaginaProdottiComponent: autocompleteRequest ended with error = '+this.error);
      }
    );
  }

  pickSuggestion(suggestion: string){
    this.logger.log("PaginaProdottiComponent: suggestion ("+suggestion+") picked");
    this.searchbar.value = suggestion;
    //this.hasSuggestion = false;
    this.searchInput = suggestion;
    this.startSearch();
  }

  toShoppingCart(){
    this.logger.log('PaginaProdottiComponent: ...toShoppingCart()');
    this.router.navigateByUrl("/shopping-cart");
  }

  initializeShoppingCartBadge(){
    if(this.authService.isLoggedIn() && !this.userService.userIsVendor()){
      this.logger.log('PaginaProdottiComponent: utente loggato, inizializzazione badge del carrello tramite API');
        
      this.shoppingCartManagerService.getShoppingCartBadge('PaginaProdottiComponent').subscribe(
        posts => {
          this.logger.log('PaginaProdottiComponent: cartBadge returned = '+posts);
          this.cartBadge = posts;
        },
        error => {
          this.logger.log('PaginaProdottiComponent: error returned... cartBadge = null');
          this.cartBadge = null;
          this.logger.log(error);
        }
      );
    
    }else if(!this.authService.isLoggedIn()){
      this.logger.log('PaginaProdottiComponent: utente NON loggato, inizializzazione badge del carrello tramite local storage');
      
      this.shoppingCartManagerService.localCart_storageKeys('PaginaProdottiComponent').then(
        res => {
          this.logger.log(res);
          this.cartBadge = res;
        }
      )
    }
  }

  onReloadProducts(){
    // Retry clicked by the user..... attempt to reload categories
    this.logger.log('PaginaProdottiComponent: onReloadProducts() called');
    this.firstCall = true;
    this.ngOnInit();
    this.ionViewWillEnter();
  }

  onFilter(){
    this.logger.log('PaginaProdottiComponent: onFilter() called');

    this.noResults = false;
    this.filterMode = true;

    this.logger.log(this.choiceCategory);
  }

  onCloseFilter(){
    this.logger.log('PaginaProdottiComponent: onCloseFilter() called');

    this.filterMode = false;
  }

  doFilter(){
    this.logger.log('PaginaProdottiComponent: doFilter() called');

    this.filterMode = false;
    this.isFetching = true;
    this.hasActiveFilters = true;

    if(this.min_price_input==null && this.max_price_input==null && this.media_recensioni_filter==0 && 
      this.choiceCategory=='Nessuna' && this.choiceSorting=='Nessuno'){
        // I filtri sono stati resettati prima che venisse lanciata la ricerca
        // Tutti i prodotti devono essere mostrati
        this.firstCall = true;
        this.next = null;
        this.prodotti = null;
        this.page_title = "Prodotti";
        this.hasActiveFilters = false;
        this.getProducts(null,null);
        return;
      }

    if(this.min_price_input!=null && this.min_price_input!='' && !isNaN(Number(this.min_price_input))){
      this.min_price = Number(this.min_price_input);
    }else{
      this.min_price = 0;
    }

    if(this.max_price_input!=null && this.max_price_input!='' && !isNaN(Number(this.max_price_input))){
      this.max_price = Number(this.max_price_input);
    }else{
      this.max_price = 0;
    }

    this.logger.log('PaginaProdottiComponent: doFilter ---> max_price = '+this.max_price+' e min_price = '+this.min_price);

    if(this.searchInput==null){  
      this.searchInput = '';
    }

    var requestParams = {
      filter_key: '',
      t: '',
      o: 'h_rec'  // Default ---> high recensioni ---> Media recensioni più alta
    }
    if(this.media_recensioni_filter!=0){
      // Media Recensioni impostata nel filtro
      requestParams['filter_key'] = 'media_recensioni';
      requestParams['t'] = '' + this.media_recensioni_filter;
    }else if(this.media_recensioni_filter==0){
      // Media Recensioni non impostata nel filtro, quindi viene comunque richiesta (è il default)
      requestParams['filter_key'] = 'media_recensioni';
      requestParams['t'] = '0';
    }
    
    if(this.max_price!=0){
      if(requestParams['filter_key'] != ''){
        requestParams['filter_key'] = requestParams['filter_key'] + '-' + 'prezzo_cad_max';
        requestParams['t'] = requestParams['t'] + '-' + this.max_price;
      }else{
        requestParams['t'] = '' + this.max_price;
        requestParams['filter_key'] = 'prezzo_cad_max';
      }
    }
    if(this.min_price!=0){
      if(requestParams['filter_key'] != ''){
        requestParams['filter_key'] = requestParams['filter_key'] + '-' + 'prezzo_cad_min';
        requestParams['t'] = requestParams['t'] + '-' + this.min_price;
      }else{
        requestParams['filter_key'] = 'prezzo_cad_min';
        requestParams['t'] = '' + this.min_price;
      }
    } 

    // Setting the category chosen... 
    if(this.choiceCategory!="Nessuna"){
      this.logger.log('PaginaProdottiComponent: filtro categoria scelto = '+this.choiceCategory)
      this.type = 'category';
      for(let c of this.categories){
        if(c.nome==this.choiceCategory){
          // Getting category ID
          this.id = c.id;
          break;
        }
      } 
      this.page_title = this.choiceCategory; 
      
      requestParams['category'] = '' + this.id;

      if(this.media_recensioni_filter==0 && this.min_price==0 && this.max_price==0){
        // Se la categoria è presente ma nient'altro viene selezionato, 
        // allora filtro per categoria e media_recensioni maggiore o uguale di zero
        requestParams['filter_key'] = 'media_recensioni';
        requestParams['t'] = '0';
      }
    }else{
      // Nessuna categoria
      this.page_title = "Prodotti";
      this.id = null; 
      this.type = null;
    }

    if(this.choiceSorting!='Nessuno'){
      // Imposto l'ordinamento
      requestParams['o'] = this.choiceSorting;
      if(requestParams['filter_key']==''){
        // Se solo l'ordinamento è stato scelto, filtro per media recensioni e t=0 (filtro nullo)
        requestParams['filter_key'] = 'media_recensioni';
        requestParams['t'] = '0';
      }
    }else{
      // Imposto l'ordinamento di default
      requestParams['o'] = 'h_rec';
    }

    this.filter(requestParams);
  }

  filter(requestParams: any){
    this.logger.log('PaginaProdottiComponent: filtrando con parametri = ');
    this.logger.log(requestParams);
    
    this.getProdottiFilteredService.fetchProductsByUrlAndParams(null,requestParams).subscribe(
      posts => {
        this.isFetching = false;
          this.resArray = <Prodotti>posts;
          this.logger.log('PaginaProdottiComponent: GET retrieved something...');
          this.logger.log(this.resArray);
          this.next = this.resArray.next;
          this.logger.log('PaginaProdottiComponent: next parameter');
          this.logger.log(this.next);
          this.next_index = this.next_index + 10;
          this.logger.log('PaginaProdottiComponent: GET results:');
          // I vecchi elementi appartengono a vecchie ricerche
          // Quindi in questo caso solo quelli legati al filtro vengono aggiunti in lista
          this.prodotti = this.resArray['results'];
          this.logger.log(this.prodotti);
          if(this.prodotti.length>0){
            this.hasProdotti = true;
            this.noResults = false;

            this.checkIfBottomReached();
          }else{
            this.noResults = true;
          }
        },
        error => {
          this.isFetching = false;
          this.hasProdotti = false;
          this.error = error.message;
          this.logger.log('PaginaProdottiComponent: GET..... an error occurred');
        });
  }

  resetFilter(){
    this.logger.log('PaginaProdottiComponent: resetFilter() called');
    // Nessuna categoria
    this.id = null; 
    this.type = null;
    // Reset variabili dei filtri
    this.media_recensioni_filter = 0;
    this.min_price = 0;
    this.max_price = 0;
    this.choiceCategory = 'Nessuna';
    this.choiceSorting = 'Nessuno';
    this.min_price_input = null;
    this.max_price_input = null;
  }

  setMediaRec(media: number){
    if(media==this.media_recensioni_filter){
      // Viene cliccato due volte lo stesso item
      this.media_recensioni_filter = media - 1;
      this.logger.log('PaginaProdottiComponent: setMediaRec set media to '+this.media_recensioni_filter);
    }else{
      this.media_recensioni_filter = media;
      this.logger.log('PaginaProdottiComponent: setMediaRec set media to '+this.media_recensioni_filter);
    }
  }

  onChangeSelectCategory(){
    this.logger.log('Nuova scelta categorie: '+this.choiceCategory);
  }

  onChangeSelectSorting(){
    this.logger.log('Nuova scelta ordinamento: '+this.choiceSorting);
  }

  onInsertToCart(prodotto: ProdottoItem, choice: any){
    this.logger.log('PaginaProdottiComponent: onInsertToCart called');

    // DOC: Valuto se aggiornare il token prima di richiedere i dati della pagina al backend
    // Se il token è scaduto, allora eseguo un refresh e poi chiedo i dati..
    if(this.authService.hasToRefreshBasicAuth()){
      this.authService.refreshBasicAuthToken().then(
        go => {
          this.insertingIntoCart(prodotto, choice);
        }
      )
    }else{
      // ..Altrimenti chiedo i dati senza eseguire il refresh
      this.insertingIntoCart(prodotto, choice);
    }
  }

  onInsertToCartProdWithOptions(prodotto: ProdottoItem, choice: any){
    this.logger.log('PaginaProdotti: onInsertToCartProdWithOptions() demanda al metodo onInsertToCart() di questo component');
    this.onInsertToCart(prodotto, choice);
  }

  insertingIntoCart(prodotto: ProdottoItem, choice: any){
    if(this.authService.isLoggedIn()){
      this.logger.log('PaginaProdottiComponent: insertingIntoCart() usa la choice seguente: '+JSON.stringify(choice));

      this.shoppingCartManagerService.insertIntoShoppingCart('PaginaProdottiComponent', prodotto, choice).subscribe(
          posts => {
            this.logger.log(posts);
            this.logger.log('PaginaProdottiComponent: terminata correttamente la post per inserimento prodotto nel carrello');
            this.shoppingCartManagerService.getShoppingCartBadge('PaginaProdottiComponent').subscribe(
              posts => {
                this.logger.log('PaginaProdottiComponent: cartBadge returned = '+posts);
                this.cartBadge = posts;
              },error => {
                this.logger.log('PaginaProdottiComponent: error returned... cartBadge = null');
                this.cartBadge = null;
                this.logger.log(error);
              });
          },
          error => {
            this.logger.log(error.message);
          }
        )
      this.logger.log('PaginaProdottiComponent: utente loggato, inserimento nel carrello remoto...');
    }else{
        this.logger.log('PaginaProdottiComponent: utente non loggato, inserimento nel carrello locale...');
        var fake_options = null;
        var local_prod_key: string = prodotto.id;
        // SOLO il prod per il carrello locale ha le opzioni uguali alla sola scelta utente
        if(prodotto.options && prodotto.options[0] && prodotto.options[0].id){
          fake_options = [{id: prodotto.options[0].id, choices:[choice]}];
          prodotto.prezzo_vetrina = choice.prezzo_vetrina;
          prodotto.quantita_disponibile = choice.quantita_disponibile;
          // In questo modo nel carrello locale ogni prodotto X con opzione Y è diverso da prod X con opzione Z
          // mentre il prod X con opzione Y va a modificare proprio il prod X con opzione Y 
          local_prod_key = local_prod_key +'-'+ choice.id;
        }

        if(prodotto.immagini[0]){
            this.shoppingCartManagerService.localCart_putObject(local_prod_key, prodotto.nome, prodotto.immagini[0].immagine, 1, ''+prodotto.prezzo_vetrina, prodotto.prezzo_trasporto, prodotto.venditore.id, 
              prodotto.venditore.titolo, prodotto.quantita_disponibile, fake_options).then(
              res => {
                this.cartBadge = res;
              });
        }else{
            this.shoppingCartManagerService.localCart_putObject(local_prod_key, prodotto.nome, 'none', 1, ''+prodotto.prezzo_vetrina, prodotto.prezzo_trasporto, prodotto.venditore.id, 
              prodotto.venditore.titolo, prodotto.quantita_disponibile, fake_options).then(
              res => {
                this.cartBadge = res;
              });
        }
    }
  }

  removeSortingFilter(){
    this.logger.log('PaginaProdottiComponent: removeSortingFilter() called');
    this.choiceSorting = 'Nessuno';
    this.doFilter();
  }

  removeCategoryFilter(){
    this.logger.log('PaginaProdottiComponent: removeCategoryFilter() called');
    this.choiceCategory = 'Nessuna';
    this.doFilter();
  }

  removeMediaRecFilter(){
    this.logger.log('PaginaProdottiComponent: removeMediaRecFilter() called');
    this.media_recensioni_filter = 0;
    this.doFilter();
  }

  removeMinPriceFilter(){
    this.logger.log('PaginaProdottiComponent: removeMinPriceFilter() called');
    this.min_price = 0;
    this.min_price_input = '0';
    this.doFilter();
  }

  removeMaxPriceFilter(){
    this.logger.log('PaginaProdottiComponent: removeMaxPriceFilter() called');
    this.max_price = 0;
    this.max_price_input = '0';
    this.doFilter();
  }

  removePriceRangeFilter(){
    this.logger.log('PaginaProdottiComponent: removePriceRangeFilter() called');
    this.min_price = 0;
    this.max_price = 0;
    this.min_price_input = '0';
    this.max_price_input = '0';
    this.doFilter();
  }

}
