import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { ProdottoItem } from "src/app/shared/models/Prodotti.model";
import { AuthService } from "src/app/shared/services/auth.service";
import { ConstantUtilsService } from "src/app/shared/services/constant-utils.service";
import { UserService } from "src/app/shared/services/User.service";
import { SwipeProdottiComponent } from "../swipe-prodotti.component";
import { Platform } from "@ionic/angular";
import { NGXLogger } from "ngx-logger";
import { Option } from "src/app/shared/models/Opzioni.models";


@Component({
    selector: 'app-scheda-prodotto',
    templateUrl: './scheda-prodotto.component.html',
    styleUrls: ['./scheda-prodotto.component.scss'],
})
export class SchedaProdottoComponent implements OnInit {
    @Input() swiper = null;
    @Input() prodotto: ProdottoItem = null;
    @Input() index: number;
    @Input() isLoading: boolean;
    optionsDict = {};
    private choicesDict = [];
    hasOptions: boolean = false;
    hasChosenOption: boolean = false;
    currentPrezzoVetrina: number;
    chosenOptionItem: any;
    private oldOptionTitle: string;

    TAGNameToFullName = {
        ProdCert: 'Prodotto Certificato',
        AzCert: 'Azienda Certificata',
        MatRic: 'Materiali Riciclati',
        MatCert: 'Materiali Certificati',
        Ric: 'Riciclabile',
        Km0: 'Km Zero',
        MatIta: 'Materiali Italiani',
        MiI: 'Made in Italy',
        Biod: 'Biodegradabile',
        Comp: 'Compostabile',
        MinWaste: 'Minimum Waste',
        EnSost: 'Energia Sostenibile',
        EcoDes: 'Eco Design',
        EcoLif: 'Eco Lifestyle',
        CulEco: 'Cultura Ecosostenibile',
        Altro: 'Altro',
        SostSoc: 'Sostenibilità Sociale',
        CausaGiusta: 'Causa Giusta',
        CulSost: 'Cultura Sostenibile',
        /*ComDeb: 'Comunità Deboli',*/
        Int: 'Integrazione'
      }

    constructor(
        private userService: UserService, private logger: NGXLogger,
        public constantUtilsService: ConstantUtilsService,
        private router: Router, public platform: Platform
    ){ }

    ngOnInit() { 
        this.logger.log('SchedaProdotto: ngOnInit');
        this.optionsRetriever();
    }

    private optionsRetriever(){
        this.optionsDict = {};
        //this.logger.log('Prodotto = '+JSON.stringify(this.prodotto)); // DEBUG
        this.logger.log('Options nel prodotto: '+JSON.stringify(this.prodotto.options)); // DEBUG
        if(this.prodotto.options && this.prodotto.options.length>0){
            this.hasOptions = true;
            this.logger.log('optionsRetriever working...');
            for(let opt of this.prodotto.options){
                //this.logger.log('option nel prodotto: '+JSON.stringify(opt)); // DEBUG
                if(this.optionsDict[opt.titolo] && this.optionsDict[opt.titolo].choices){
                    for(let choice of opt.choices){
                        this.logger.log(JSON.stringify(this.choicesDict)); // DEBUG
                        this.logger.log('indexof (id): '+this.choicesDict.indexOf(choice.id)); // DEBUG
                        if(this.choicesDict.indexOf(choice.id)==-1){
                            this.logger.log('INSERITA LA CHOICE CON ID = '+choice.id); // DEBUG
                            // Manipolando il dict delle opzioni che serve alla UI
                            choice.selected = false;
                            this.optionsDict[opt.titolo].choices.push(choice);
                            // Manipolando il dict delle scelte che serve per selezionare le scelte
                            this.choicesDict[choice.id] = choice;
                            this.logger.log('Scelte inserite per '+opt.titolo+' '+JSON.stringify(this.optionsDict[opt.titolo].choices)); // DEBUG
                        }
                    }
                    //this.logger.log(this.optionsDict[opt.titolo]); // DEBUG
                }else{
                    this.logger.log(JSON.stringify(this.choicesDict)); // DEBUG
                    this.logger.log('indexof (id): '+this.choicesDict.indexOf(opt.choices[0].id)); // DEBUG
                    if(this.choicesDict.indexOf(opt.choices[0].id)==-1){
                        this.logger.log('INSERITA LA CHOICE CON ID = '+opt.choices[0].id); // DEBUG
                        // Manipolando il dict delle opzioni che serve alla UI, inserendo la prima opzione
                        opt.choices[0].selected = false;
                        this.optionsDict[opt.titolo] = {id:opt.id, titolo:opt.titolo, choices:[opt.choices[0]]};
                        // Manipolando il dict che serve per selezionare le scelte, inserendo la prima scelta
                        this.choicesDict[opt.choices[0].id] = opt.choices[0];
                        this.logger.log('Scelte inserite per '+opt.titolo+' '+JSON.stringify(this.optionsDict[opt.titolo].choices)); // DEBUG
                    }
                }
            }
        }else{
            this.hasOptions = false;
        }
    }

    onClickToChoice(id: number, titoloOpzione: string){
        this.logger.log('onClickToChoice: chiamato con ID = '+id);
        if(this.choicesDict[id]){
            // Ottengo la vecchia selezione
            var oldSelection = this.chosenOptionItem;
            // Ottengo la nuova selezione
            this.chosenOptionItem = this.choicesDict[id];
            this.swiper.setChosenOption(this.chosenOptionItem);
            this.logger.log('scelta da utente = '+JSON.stringify(this.chosenOptionItem));
            if(oldSelection && oldSelection.id!=this.chosenOptionItem.id){
                this.hasChosenOption = true;
                this.swiper.onChosenOption();
            }else if(oldSelection && oldSelection.id==this.chosenOptionItem.id){
                this.hasChosenOption = false;
                this.chosenOptionItem = null;
                this.swiper.onDeactivateChoice();
            }else{
                this.hasChosenOption = true;
                this.swiper.onChosenOption();
            }
            
            // Prendo la selezione dell'utente e rimuovo la vecchia selezione
            if(titoloOpzione && this.optionsDict[titoloOpzione]){
                for(let choice of this.optionsDict[titoloOpzione].choices){
                    if(choice.id==id && !choice.selected){
                        this.logger.log('..impostando la selezione sulla scelta: '+choice.id);
                        // Visualizzo la nuova selezione nella UI
                        choice.selected = true;
                        this.currentPrezzoVetrina = choice.prezzo_vetrina;
                    }
                }
                if(oldSelection && this.oldOptionTitle){
                    for(let choice of this.optionsDict[this.oldOptionTitle].choices){
                        if(choice.id==oldSelection.id && choice.selected){
                            this.logger.log('..deselezionando la scelta: '+choice.id);
                            // Deseleziono la vecchia scelta nella UI
                            choice.selected = false;
                        }
                    }
                }
            }

            if(this.optionsDict[titoloOpzione]){
                // Salvo il titolo dell'opzione corrente
                this.oldOptionTitle = titoloOpzione;
            }
        }
    }

    hasPreviousProduct(){
        return this.index !== 0;
    }

    moveToPreviousProduct(){
        if(this.swiper){
            this.swiper.moveToPreviousProduct();
        }
    }

    hasNextProduct(){
        if(this.swiper){
            return this.swiper.hasNextProduct(this.index);
        }
        return false;
    }

    moveToNextProduct(){
        if(this.swiper){
            this.swiper.moveToNextProduct();
        }
    }

    // TODO: Alla fine valuta se questo metodo serve a qualcosa
    /*onInsertToCart(){
        if(this.swiper){
            this.swiper.insertToCart(this.prodotto);
        }
    }*/

    // TODO: Alla fine valuta se questo metodo serve a qualcosa
    /*
    onInsertToCartProdWithOptions(){
        if(this.swiper && this.hasChosenOption){
            this.swiper.insertToCart(this.prodotto);
            this.logger.log('SchedaProdotto: onInsertToCartProdWithOptions() ha inserito nel carrello il prodotto!');
        }else if(this.swiper && !this.hasChosenOption){
            this.logger.log('SchedaProdotto: onInsertToCartProdWithOptions() triggera il messaggio in rosso nello swiper..');
            this.swiper.highlightOptionsMessage();
        }
    }*/

    moveToReviewsPage(){
        this.logger.log("Navigation to reviews page for product with id", this.prodotto.id);
        const destinationURL = '/products/' + this.prodotto.id + '/reviews' 
        // const destinationURL = '/products/reviews' 
        this.router.navigateByUrl(destinationURL);
        this.swiper.close();
    }
    
    toProductDetail(){
        this.logger.log('SchedaProdotto: toProductDetail() called with product id = '+this.prodotto.id);
        this.router.navigateByUrl("/products/" + this.prodotto.id + "/detail");
        this.swiper.close();
    }

    toVendor(){
        this.logger.log('SchedaProdotto: toVendor() called');
        this.router.navigateByUrl("vendors/vendor/"+this.prodotto.venditore.id);
        this.swiper.close();
    }
}