import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { GetVendorUserService } from 'src/app/shared/services/getVendorUser.service';
import { VendorInfo, VendorProfile } from 'src/app/shared/models/VendorInfo.model';
import { GetProdottiService } from 'src/app/shared/services/getProdotti.service';
import { Prodotti, ProdottoItem } from 'src/app/shared/models/Prodotti.model';
import { Subscription } from 'rxjs';
import { ProdottiParentComponent } from 'src/app/products/prodotti-parent.interface';
import { UserService } from 'src/app/shared/services/User.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ConstantUtilsService } from 'src/app/shared/services/constant-utils.service';
import { AlertController, Platform, PopoverController } from '@ionic/angular';
import { ShoppingCartManagerService } from 'src/app/shared/services/shopping-cart-manager.service';
import { VendorOrderService } from 'src/app/shared/services/vendor-orders.service';
import { MenuService } from 'src/app/shared/services/menu.service';
import { SignalComponent } from 'src/app/signal/signal.component';
import { AssociationPopover } from 'src/app/association/association-popover/association-popover.component';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.scss'],
})
export class VendorProfileComponent implements OnInit, ProdottiParentComponent {

  myProfile: boolean = false;
  hasProdotti: boolean = false;

  errorMsg: string;
  
  // Vendor data params
  isFetchingVendorData: boolean = true;
  private vendorId: string;
  vendorUserData: any;
  vendorMainAddress: string;
  vendorMediaRecensioni: number = 0;
  
  // Vendor Products params
  isFetchingVendorProducts: boolean = true;
  private productsErrorSubscription: Subscription;
  private productsRequestParams = {'order_key': 'media_recensioni', 'ordering': 'desc', 'venditore': null};
  products: ProdottoItem[] = [];
  private next: string;
  // private nextIndex: number = 0;
  private firstCall: boolean = true;
  focusDescrizione: boolean = false;
  focusBiografia: boolean = false;

  pop: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router, private shoppingCartManagerService: ShoppingCartManagerService,
    public authService: AuthService, public vendorOrdersService: VendorOrderService,
    public userService: UserService, private logger: NGXLogger,
    private getVendorUserService: GetVendorUserService, 
    private getProdottiService: GetProdottiService,
    public constantUtilsService: ConstantUtilsService,
    private alertController: AlertController, private popover: PopoverController,
    public platform: Platform, private menuService: MenuService
  ) { }

  ngOnInit() {
    this.logger.log("ProfiloVenditoreComponent: --------------->   START   <-----------------");
    this.logger.log(this.router.url);
    if(this.router.url !== "/myprofile/vendor" && this.activatedRoute.snapshot.paramMap.get('id')!=null){
      this.vendorId = this.activatedRoute.snapshot.paramMap.get('id');
      this.logger.log("ProfiloVenditoreComponent: generic vendor profile...");
      if(this.userService.userIsVendor() && this.userService.userData && this.userService.userData.id === this.vendorId){
        this.logger.log('UserData.id = '+this.userService.userData.id);
        this.myProfile = true;
        this.logger.log(this.vendorId)
        this.logger.log("ProfiloVenditoreComponent: actually, myprofile vendor profile!");
      }
    }else{
      this.myProfile = true
      this.vendorId = this.userService.userData["id"]
      this.logger.log(this.vendorId)
      this.logger.log("ProfiloVenditoreComponent: actually, myprofile vendor profile!");
    }
  }

  ionViewWillEnter(){
    this.logger.log("ProfiloVenditoreComponent: id parameter=", this.vendorId);
    this.next = null;
    this.products = null;
    this.products = [];
    this.firstCall = true;
    this.getVendorData();
    this.logger.log(this.vendorId)
    this.productsRequestParams.venditore = this.vendorId;
    this.logger.log(this.productsRequestParams)
    this.getProducts(null, this.productsRequestParams);
  }

  isMyProfile(){
    const myProfileURL = this.router.url === "/myprofile/vendor"
    const myVendorId = this.userService.userIsVendor() && this.userService.userData["id"] === this.vendorId;
    return myProfileURL || myVendorId;
  }

  getVendorData(){
    this.logger.log("ProfiloVenditoreComponent: --------->   START getVendorInfo  <---------");

    this.isFetchingVendorData = true;
    if(this.isMyProfile()){
      this.logger.log('ProfiloVenditoreComponent: è il profilo del venditore corrente...');
      // Modifico il Menu rendendolo menu per venditore
      this.menuService.setVendorMenu();
      // Procedo poi normalmente con la richiesta del token...
      if(this.authService.isLoggedIn()){
        // DOC: Valuto se aggiornare il token prima di richiedere i dati della pagina al backend
        // Se il token è scaduto, allora eseguo un refresh e poi chiedo i dati..
        if(this.authService.hasToRefreshBasicAuth()){
          this.authService.refreshBasicAuthToken().then(
            go => {
              this.getVendorProfile();
            }
          )
        }else{
          // ..Altrimenti chiedo i dati senza eseguire il refresh
          this.getVendorProfile();
        }
      }
    }else{
      this.logger.log('ProfiloVenditoreComponent: NON è il profilo del venditore corrente...');
      this.getVendorInfo();
    }
  }

  getVendorProfile(){
    // l'API per il profilo del venditore è diversa e più completa rispetto a quella per venditore generico
    this.getVendorUserService.fetchVendorProfile().subscribe(
      (vendorDataResponse: VendorProfile) => {
        this.logger.log("ProfiloVenditoreComponent.getVendorInfo", "Positive Result", vendorDataResponse);
        this.vendorUserData = vendorDataResponse;
        this.isFetchingVendorData = false;
        if(this.vendorUserData.residenza){
          for(let r of this.vendorUserData.residenza){
            if(r.preferito || this.vendorUserData.residenza.length==1){
              //this.vendorMainAddress = r.città+', '+r.paese; // OLD
              this.vendorMainAddress = r.regione;
              break;
            }
          }
        }

      },
      error => {
        this.isFetchingVendorData = false;
        this.errorMsg = error.message;
        console.error('ProfiloVenditoreComponent.getVendorProfile', "Error", error, this.errorMsg);
        if(error.status==403){
          this.recoverFromHTTP403();
        }
      }
    );
  }

  recoverFromHTTP403(){
    this.logger.log('Recovering from HTTP403.....');
    this.errorMsg = null;
    this.authService.refreshBasicAuthToken().then(
      ok => {
        this.ionViewWillEnter();
      },
      err => {
        this.logger.log('..NOT recovered');
        this.router.navigate(['home']);
      }
    )
  }

  getVendorInfo(){
    // API per venditore generico
    this.getVendorUserService.fetchVendorInfo(this.vendorId).subscribe(
      (vendorDataResponse: VendorInfo) => {
        // this.logger.log("ProfiloVenditoreComponent.getVendorInfo", "Result", vendorDataResponse);
        this.vendorUserData = vendorDataResponse
        this.isFetchingVendorData = false;
        this.vendorMediaRecensioni = parseInt(this.vendorUserData.media_recensioni);
        if(this.vendorUserData.residenza[0]){
          // Nel getVendorInfo basta questo perchè la regione viene restituita tramite una relazione Django
          this.vendorMainAddress = this.vendorUserData.residenza[0];
        }
        this.logger.log('ProfiloVenditoreComponent: media recensioni = '+this.vendorMediaRecensioni+' e indirizzo = '+this.vendorMainAddress);
      },
      error => {
        this.isFetchingVendorData = false;
        this.errorMsg = error.message;
        console.error('ProfiloVenditoreComponent.getVendorInfo', "Error", error, this.errorMsg);
      }
    );
  }

  loadData(event) {
    this.getProducts(event, null);
    event.target.complete();
  }

  moreDataToLoad(){
    return this.next != null || this.firstCall;
  }

  getProdotti(){
    return this.products;
  }

  fetchingProducts(){
    return this.isFetchingVendorProducts;
  }

  getProducts(event, requestParams){
    this.logger.log('ProfiloVenditoreComponent: getProducts <------------');

    if(!this.moreDataToLoad()){
      this.logger.log("PaginaProdottiComponent.getProducts", "No more data to load")
      return;
    }

    // Call getProdottiService to load more products
    this.firstCall = false;
    this.productsErrorSubscription = this.getProdottiService.error.subscribe(errorMessage => {
      this.errorMsg = errorMessage;
    });

    this.isFetchingVendorProducts = true;
    this.getProdottiService.fetchProductsByUrlAndParams(this.next, requestParams).subscribe(
      (productsResponse: Prodotti) => {
        this.logger.log("ProfiloVenditoreComponent.getProducts", productsResponse);
        this.next = productsResponse.next;
        this.products = this.products.concat(productsResponse['results']);
        if(this.hasProducts()){
          this.hasProdotti = true;
        }else{
          this.hasProdotti = false;
        }
        this.isFetchingVendorProducts = false;
      },
      error => {
        this.errorMsg = error.message;
        console.error("PaginaProdottiComponent.getProducts", "An error occurred", this.errorMsg);
        this.isFetchingVendorProducts = false;
      }
    )
  }

  onReloadProfile(){
    this.authService.initUserToken();
    this.errorMsg = null;
    this.onReloadProducts();
    this.onReloadVendorData();
  }

  onReloadProducts(){
    // Retry clicked by the user..... attempt to reload products
    this.logger.log("ProfiloVenditoreComponent.onReloadProducts()");
    this.firstCall = true;
    this.getProducts(null, this.productsRequestParams);
  }

  onReloadVendorData(){
    // Retry clicked by the user..... attempt to reload products
    this.logger.log("ProfiloVenditoreComponent.onReloadVendorData()");
    this.getVendorData();
  }

  hasProducts(){
    return this.products.length > 0;
  }

  onEditAddress(){
    this.logger.log("ProfiloVenditoreComponent.onEditAddress()");
    this.router.navigateByUrl('/shipping-address');
  }

  toAddressList(){
    let navigationExtras: NavigationExtras = {
      state: {
        type: 'vendor'
      }
    };
    this.router.navigate(["/myprofile/vendor/shipping-address/edit"], navigationExtras);
  }

  // The same version with respect to Pagina Prodotti's one, but with some simplification 
  onInsertToCart(prodotto: ProdottoItem, choice: any){
    this.logger.log('ProfiloVenditoreComponent: onInsertToCart called');
    // DOC: Valuto se aggiornare il token prima di richiedere i dati della pagina al backend
    // Se il token è scaduto, allora eseguo un refresh e poi chiedo i dati..
    if(this.authService.hasToRefreshBasicAuth()){
      this.authService.refreshBasicAuthToken().then(
        go => {
          this.insertingIntoCart(prodotto, choice);
        }
      )
    }else{
      // ..Altrimenti chiedo i dati senza eseguire il refresh
      this.insertingIntoCart(prodotto, choice);
    }
  }

  onInsertToCartProdWithOptions(prodotto: ProdottoItem, choice: any){
    this.logger.log('VendorProfile: onInsertToCartProdWithOptions() demanda al metodo onInsertToCart() di questo component');
    this.onInsertToCart(prodotto, choice);
  }

  private insertingIntoCart(prodotto: ProdottoItem, choice: any){
    if(this.authService.isLoggedIn()){
      this.logger.log('ProfiloVenditoreComponent: insertingIntoCart() usa la choice seguente: '+JSON.stringify(choice));

      this.shoppingCartManagerService.insertIntoShoppingCart('ProfiloVenditoreComponent', prodotto, choice).subscribe(
          posts => {
            this.logger.log(posts);
            this.logger.log('ProfiloVenditoreComponent: terminata correttamente la post per inserimento prodotto nel carrello');
            this.shoppingCartManagerService.getShoppingCartBadge('ProfiloVenditoreComponent').subscribe(
              posts => {
                this.logger.log('ProfiloVenditoreComponent: cartBadge returned = '+posts);
                //this.cartBadge = posts; // Non serve, non viene usato il badge carrello
              },error => {
                this.logger.log('ProfiloVenditoreComponent: error returned... cartBadge = null');
                //this.cartBadge = null; // Non serve, non viene usato il badge carrello
                this.logger.log(error);
              });
          },
          error => {
            this.logger.log(error.message);
          }
        )
      this.logger.log('ProfiloVenditoreComponent: utente loggato, inserimento nel carrello remoto...');
    }else{
        this.logger.log('ProfiloVenditoreComponent: utente non loggato, inserimento nel carrello locale...');
        var fake_options = null;
        var local_prod_key: string = prodotto.id;
        // SOLO il prod per il carrello locale ha le opzioni uguali alla sola scelta utente
        if(prodotto.options && prodotto.options[0] && prodotto.options[0].id){
          fake_options = [{id: prodotto.options[0].id, choices:[choice]}];
          prodotto.prezzo_vetrina = choice.prezzo_vetrina;
          prodotto.quantita_disponibile = choice.quantita_disponibile;
          // In questo modo nel carrello locale ogni prodotto X con opzione Y è diverso da prod X con opzione Z
          // mentre il prod X con opzione Y va a modificare proprio il prod X con opzione Y 
          local_prod_key = local_prod_key +'-'+ choice.id;
        }

        if(prodotto.immagini[0]){
            this.shoppingCartManagerService.localCart_putObject(local_prod_key, prodotto.nome, prodotto.immagini[0].immagine, 1, ''+prodotto.prezzo_vetrina, prodotto.prezzo_trasporto, prodotto.venditore.id, 
              prodotto.venditore.titolo, prodotto.quantita_disponibile, fake_options).then(
              res => {
                this.logger.log(res);
                //this.cartBadge = res; // Non serve, non viene usato il badge carrello
              });
        }else{
            this.shoppingCartManagerService.localCart_putObject(local_prod_key, prodotto.nome, 'none', 1, ''+prodotto.prezzo_vetrina, prodotto.prezzo_trasporto, prodotto.venditore.id, 
              prodotto.venditore.titolo, prodotto.quantita_disponibile, fake_options).then(
              res => {
                this.logger.log(res);
                //this.cartBadge = res; // Non serve, non viene usato il badge carrello
              });
        }
    }
  }

  onFocusDescrizione(){
    this.logger.log('VendorComponent: onFocusDescrizione called...');
    this.focusDescrizione = true;
  }

  onFocusBiografia(){
    this.logger.log('VendorComponent: onFocusBiografia called...');
    this.focusBiografia = true;
  }

  onReduceDescrizione(){
    this.logger.log('VendorComponent: onReduceDescrizione called...');
    this.focusDescrizione = false;
  }

  onReduceBiografia(){
    this.logger.log('VendorComponent: onReduceBiografia called...');
    this.focusBiografia = false;
  }

  toAddNewProduct(){
    this.logger.log('VendorComponent: toAddNewProduct() called');
    if(this.vendorMainAddress==null){
      this.comeBackAndInsertNewAddress('Nessun indirizzo presente', 'Nessun indirizzo inserito per il tuo negozio. Per favore, inserisci almeno un indirizzo di spedizione e riprova!');
    }else{
      let navigationExtras: NavigationExtras = {
        state: {
          tipologia: this.vendorUserData.tipologia
        }
      };
      this.router.navigate(['/myprofile/vendor/products/add'], navigationExtras);
    }
  }

  async comeBackAndInsertNewAddress(header: string, text: string){
    if(!text || !header){
      header = 'Nessun indirizzo inserito'
      text = 'Inserisci il tuo primo indirizzo e riprova!';
    }
    const alert = await this.alertController.create({
      header: 'Posizione del venditore',
      cssClass: 'alert-popup-style',
      subHeader: header,
      message: text,
      buttons: [
        {
          text: 'OK',
          cssClass: 'alert-popup-btn-style'
        }
      ]
    });
  
    await alert.present();
  }

  toAddNewAddress(){
    this.logger.log('ProfiloUtenteComponent: ...toAddNewAddress()');
    let navigationExtras: NavigationExtras = {
      state: {
        type: 'vendor'
      }
    };
    this.router.navigate(['myprofile/vendor/shipping-address/edit'], navigationExtras);
  }

  toReviews(){
    this.logger.log('ProfiloUtenteComponent: ...toReviews()');
    this.router.navigate(['/myprofile/vendor/reviews']);
  }

  toOrders(){
    this.logger.log('ProfiloUtenteComponent: ...toOrders()');
    this.router.navigate(['/myprofile/vendor/orders']);
  }

  onSignal(){
    this.logger.log('ProfiloVenditore: onSignal pressed...');
    if(!this.authService.isLoggedIn()){
      this.router.navigate(['login']);
    }else{
      this.CreateSignalPopover(null);
    }
  }

  async CreateSignalPopover(ev: any) {
    this.pop = await this.popover.create({
      component: SignalComponent,
      cssClass: 'signal-popover-class',
      event: ev,
      translucent: true,
      componentProps: {
        "type": 'vendor',
        "vendorID": this.vendorId
      }
    });

    this.pop.onDidDismiss().then(result => {
      if(result!=null){
         this.logger.log('ProfiloVenditore: signal popover closed with boolean = '+result.data);
      }
    });

    return await this.pop.present();
  }

  toHome(){
    this.logger.log('ProfiloVenditore: toHome() called');
    this.router.navigateByUrl("home");
  }

  async CreateAssociationPopover(ev: any, nome: string, logo: string, descrizione: string, link: string) {
    const pop = await this.popover.create({
      component: AssociationPopover,
      cssClass: 'association-popover-style-class',
      event: ev,
      translucent: true,
      componentProps: {
        "nome": nome,
        "logo": logo,
        "link": link,
        "descrizione": descrizione
      }
    });
    return await pop.present();
  }

}
