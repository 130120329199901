import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, ParamMap, Params, Router } from '@angular/router';
import { IonContent, Platform, PopoverController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { OrderDetail, UOIElement, UserOrderItem, UserOrdersListResponse } from 'src/app/shared/models/Ordini.models';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ConstantUtilsService } from 'src/app/shared/services/constant-utils.service';
import { UserOrderService } from 'src/app/shared/services/user-orders.service';
import { ShippingPopover } from './shipping-popover/shipping-popover.component';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-buyer-order',
  templateUrl: './buyer-order.component.html',
  styleUrls: ['./buyer-order.component.scss'],
})
export class BuyerOrderComponent implements OnInit {

  @ViewChild(IonContent, {static: false}) private content: IonContent;
  pop: any;

  // order detail variables
  private paramMap: ParamMap;
  isFetching: boolean = true;
  error404: boolean = false;
  error: boolean = false;
  orderDetail: OrderDetail;

  // order items variables
  assistance: boolean = false;
  emailstring: string = 'mailto:assistenza@emplacestore.it?Subject=Richiesta di supporto cliente';
  email: string = 'assistenza@emplacestore.it';
  orderItems: UOIElement[];
  hasItems: boolean = false;
  orderItemsError = null;

  constructor(private userOrderService: UserOrderService, private router: Router, private popover: PopoverController,
    private activatedRoute: ActivatedRoute, public constantUtils: ConstantUtilsService,
    public platform: Platform, private authService: AuthService, private logger: NGXLogger) { }

  ngOnInit() {
    this.logger.log('BuyerOrderComponent: ngOnInit');
    
    this.activatedRoute.queryParams.subscribe(params => {
      // tutta la parte di elaborazione dei parametri è stata sposta al di fuori di qui 
      // per mantenere questa parte asincrona il più breve possibile (e per evitare problemi di lifecycle - maggiori dettagli di seguito).
      this.paramMap = this.activatedRoute.snapshot.paramMap;
    });
    this.isFetching = true;
  }

  ionViewWillEnter(){
    this.logger.log('BuyerOrderComponent: ngOnInit');
    let orderId = this.paramMap.get('orderId')
    this.logger.log('BuyerOrderComponent: orderID ottenuto = '+orderId);

    // Richiediamo il dettaglio dell'ordine
    // DOC: Valuto se aggiornare il token prima di richiedere i dati della pagina al backend
    // Se il token è scaduto, allora eseguo un refresh e poi chiedo i dati..
    if(this.authService.hasToRefreshBasicAuth()){
      this.authService.refreshBasicAuthToken().then(
        go => {
          this.fetchOrderDetail(orderId);
        })
    }else{
      // ..Altrimenti chiedo i dati senza eseguire il refresh
      this.fetchOrderDetail(orderId);
    }
  }

  fetchOrderDetail(orderId: string){
    this.userOrderService.createBuyerOrderRequest(orderId).subscribe(
      responseData => {
        this.logger.log('BuyerOrderComponent: richiesta di dettaglio ordine ritorna:');
        this.logger.log(responseData);
        this.orderDetail = responseData;
        this.isFetching = false;

        this.emailstring = this.emailstring + ' [ordine '+this.orderDetail.id+']';

        // retrieving order elements
        this.orderItems = responseData.elementi_ordine
        if(this.orderItems != null && this.orderItems.length > 0){
          this.logger.log('BuyerOrderComponent: order items: ');
          this.logger.log(this.orderItems);
          this.hasItems = true;
          for(let item of this.orderItems){
            item.assistance = false;

            // Managing choices... 
            this.logger.log('choice_id = '+item.choice_id); // DEBUG
            this.logger.log('choice_description = '+item.choice_description); // DEBUG
            if(item.choice_id && !item.choice_description){
              for(let opt of item.prodotto.options){
                for(let choice of opt.choices){
                  if(choice.id==item.choice_id){
                    item.choice_description = choice.descrizione;
                    break;
                  }
                }
              }
            }
            this.logger.log('choice_description after processing = '+item.choice_description); // DEBUG
          }
          
        }else{
          this.logger.log('BuyerOrderComponent: order items NULL or with size = zero ');
          this.hasItems = false;
        }
      },
      error => {
        this.logger.log('BuyerOrderComponent: ERROR retrieved = '+error.status);
        this.isFetching = false;
        if(error.status == 404){
          this.error404 = true;
        }else{
          // In molti degli altri casi non dovremmo arrivare qui ma esser bloccati dalla guard
          this.logger.log('Errore inatteso: '+error.status);
          this.error = true;
        }
      }
    );
  }

  manageOrderNotFound(){
    this.logger.log('BuyerOrderComponent: ERROR 404 manager called ');
    this.router.navigate(['user/buyer/orders']);
  }

  onReloadOrderDetail(){
    this.logger.log('BuyerOrderComponent: onReloadOrderDetail called');
    this.isFetching = true;
    this.error = false;
    this.error404 = false;
    this.ionViewWillEnter();
  }

  requestAssistance(id: string){
    this.logger.log('BuyerOrderComponent: requestAssistance called');
    for(let item of this.orderItems){
      if(item.id == id){
        item.assistance = true;
      }
    }
  }

  enoughAssistance(id: string){
    this.logger.log('BuyerOrderComponent: enoughAssistance called');
    for(let item of this.orderItems){
      if(item.id == id){
        item.assistance = false;
      }
    }
  }

  toContactAdmin(){
    this.logger.log('BuyerOrderComponent: toContactAdmin called');
    // TODO:
  }

  toContactVendor(){
    this.logger.log('BuyerOrderComponent: toContactVendor called');
    // TODO:
  }

  toProductDetail(id: string){
    this.logger.log('BuyerOrderComponent: toProductDetail called with order item ID = '+id);
    //let navigationExtras: NavigationExtras = { state: { from: 'buyer-order' } };  // NO MORE USED
    this.router.navigate(['products/' + id + '/detail/']/*, navigationExtras*/);
  }

  toHome(){
    this.logger.log('BuyerOrderComponent: toHome() called');
    this.router.navigateByUrl("home");
  }

  toShippingInfo(index){
    this.logger.log('toShippingInfo called with index = '+index);
    if(this.orderItems[index] && this.orderItems[index].stato=='Spedito'){
      this.logger.log('Spedito....');
      this.CreatePopover(null, this.orderItems[index].codice_tracking, this.orderItems[index].shipping_dealer, this.orderItems[index].prefisso, this.orderItems[index].telefono);
    }else{
      this.logger.log('..non ancora spedito!');
    }
  }

  async CreatePopover(ev: any, tracking: string, corriere: string, prefisso: string, telefono: string) {
    this.pop = await this.popover.create({
      component: ShippingPopover,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
      componentProps: {
        "tracking": tracking,
        "corriere": corriere,
        "prefisso": prefisso,
        "telefono": telefono
      }
    });
    return await this.pop.present();
  }

  async ionViewWillLeave(){
    this.logger.log('BuyerOrderComponent: ----- ionViewWillLeave  ----- ');
    // Chiudo i vari popover prima di lasciare la pagina (pop = popover aperto in quel momento -> è al massimo UNO)
    if(this.pop){
      const popover = await this.popover.getTop();
      if(popover){
        await popover.dismiss();
        this.logger.log('Popover dismissed');
      }
    }
  }

  toReview(index: number){
    this.logger.log('BuyerOrderComponent: toReview() called');
    this.router.navigate(['products/'+this.orderDetail.elementi_ordine[index].prodotto.id+'/reviews']);
  }

}
