<ion-grid>
  <div *ngFor="let item of items; index as index_i">
    <ion-row class="title" style="margin-top: 5px;">
      <ion-col size="12" class="title-col" *ngIf="item!=null">
        <p class="title"><strong>{{ item['prodotto']['nome'] }}</strong></p>
      </ion-col>
    </ion-row>
    <ion-row class="cart-item" style="padding-top: 0px;" *ngIf="item!=null">
        <ion-col size-xs="6" size-sm="4" size-md="4" size-lg="4" size-xl="4" class="image-col-left" 
        *ngIf="item!=null" style="text-align: right;">
          <img src="{{this.constantUtils.getFullURL(item['prodotto']['immagini'][0]['immagine'])}}" *ngIf="item['prodotto']['immagini'][0]" onerror="this.src='assets/placeholders/product.jpg'" class="item-img-resize">
          <img src="assets/placeholders/product.jpg" *ngIf="!item['prodotto']['immagini'][0]" class="item-img-resize">
        </ion-col>
      
        <ion-col size-xs="6" size-sm="4" size-md="4" size-lg="4" size-xl="4" class="item-col" style="padding-top: 0px;" *ngIf="item!=null">
          <ion-grid>
            <ion-row>
              <ion-col size="12">
                <ion-text class="costo-item" style="color: #737373;"><strong>{{ roundTo(item['prezzo_vetrina']*item['quantità'],1) }}€</strong></ion-text>
              </ion-col>
              <ion-col size="12" style="padding-left: 0px; padding-right: 0px;">
                <ion-text style="color: #737373;">{{item['prezzo_vetrina']}}€ x {{ item['quantità'] }}pz </ion-text>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-col>

        <ion-col size-xs="12" size-sm="4" size-md="4" size-lg="4" size-xl="4" class="item-col-right" 
        *ngIf="item!=null && item.choice_descrizione && item.option_titolo" style="text-align: left;">
          <ion-grid>
            <ion-row>
              <ion-col size="12">
                <ion-text class="costo-item" style="color: #737373;"><strong>{{item.option_titolo}}</strong></ion-text>
              </ion-col>
              <ion-col size="12" style="padding-right: 0px;">
                <ion-text style="color: #737373;"><strong>{{item.choice_descrizione}}</strong></ion-text>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-col>
    </ion-row>
  </div>
</ion-grid>