<ion-app>
  <ion-split-pane contentId="main-content" when="false">
    <ion-menu contentId="main-content" type="overlay">
      <ion-content>
        <ion-list id="inbox-list">
          <div id="not-logged-in-div" *ngIf="!this.isLoggedIn">
            <ion-list-header>Benvenutə su Emplace</ion-list-header>
            <ion-note><em>Earth, here we come</em></ion-note>
          </div>
          <div id="logged-in-div" *ngIf="this.isLoggedIn">
            <ion-avatar style="margin-left: 10px;" (click)="toUserProfile()">
              <img src="{{ constantUtilsService.getFullURL(this.userData.foto_profilo) }}" *ngIf="userData && userData.foto_profilo!='Missing'" onerror="this.src='assets/placeholders/male_user.svg'">
              <img src="assets/placeholders/male_user.svg" *ngIf="userData && userData.foto_profilo=='Missing'">
            </ion-avatar>
            <div *ngIf="this.userService.userIsClient()">
              <ion-list-header class="ion-text-wrap" style="overflow: hidden;">{{this.userData.first_name}} {{this.userData.last_name}}</ion-list-header>
              <ion-note class="ion-text-wrap" style="overflow: hidden;"><em>{{this.userData.email}}</em></ion-note>
            </div>
            <div *ngIf="this.userService.userIsVendor()">
              <ion-list-header class="ion-text-wrap" style="overflow: hidden;">{{this.userData.titolo}}</ion-list-header>
              <ion-note class="ion-text-wrap" style="overflow: hidden;"><em>{{this.userData.email}}</em></ion-note>
            </div>
          </div>

          <ion-menu-toggle auto-hide="false" *ngFor="let p of menuService.menu; let i = index">
            <ion-item (click)="selectedIndex = i" routerDirection="root" [routerLink]="[p.url]" lines="none" detail="false" [class.selected]="selectedIndex == i">
              <ion-icon slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-item-divider></ion-item-divider>

          <!-- MENU OPTIONS TO SHOW WHEN THE USER IS NOT LOGGED IN -->
          <div id="not-logged-in-menu" *ngIf="!this.isLoggedIn">
            <ion-menu-toggle auto-hide="false" *ngFor="let p of menuService.accessPages; let i = index">
              <ion-item (click)="selectedIndex = i" routerDirection="root" [routerLink]="[p.url]" lines="none" 
                detail="false" [class.selected]="selectedIndex == i">
                <ion-icon slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </div><!-- MENU OPTIONS TO SHOW WHEN THE USER IS NOT LOGGED IN -->

          <!-- MENU OPTIONS TO SHOW WHEN THE USER IS LOGGED IN -->
          <div id="not-logged-in-menu" *ngIf="this.isLoggedIn">
            <ion-menu-toggle auto-hide="false" *ngFor="let p of menuService.loggedInPages; let i = index">
              <ion-item (click)="logoutModal()" lines="none" 
                detail="false">
                <ion-icon slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </div><!-- MENU OPTIONS TO SHOW WHEN THE USER IS LOGGED IN -->

        </ion-list>

        <!-- Versione X.Y.Z -->
        <!-- X è una Major change -->
        <!-- Y è una change -->
        <!-- Z è una fix di una change -->
        <p style="margin: 0px; margin-top: 32px;">
          <ion-text style="font-size: 12px; padding-left: 18px;">Versione 3.2.0</ion-text>
        </p>
        <!-- TODO: Rimuovi se riesci ad evitare il caching di index.html -->

      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
