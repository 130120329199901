import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpEventType
} from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { NewShoppingCartItem, ShoppingCart, ShoppingCartUpdate } from '../models/ShoppingCart.models';
import { ProdottoItem } from '../models/Prodotti.model';
import { ConstantUtilsService } from './constant-utils.service';
import { NGXLogger } from 'ngx-logger';
import { ProdItemChoice } from '../models/Opzioni.models';

@Injectable()
export class ShoppingCartItemsService {

  constructor(private http: HttpClient, private constantUtils: ConstantUtilsService, private logger: NGXLogger) {}
  
  private defaultUrl: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/shopping-cart-items/');
  error = new Subject<string>();
  private new_item: NewShoppingCartItem;


  fetchItemsByURL(){
    return this.fetchCartItems(null)
  }

  private fetchCartItems(searchParams){

    return this.http
    .get<ShoppingCart>(
      this.defaultUrl,
      {
        headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
        params: searchParams,                                     
        responseType: 'json'
      }
    )
  }

  // HTTP DELETE
  deletePosts() {
    return this.http
      .delete(this.defaultUrl, {
        observe: 'events',
        responseType: 'text'
      })
      .pipe(
        tap(event => {
          this.logger.log(event);
          if (event.type === HttpEventType.Sent) {
            // ...
          }
          if (event.type === HttpEventType.Response) {
            this.logger.log(event.body);
          }
        })
      );
  }

  fetchItemsByUrlAndParams(params){
    return this.fetchCartItems(params);
  }

  createItemsPost(newItems: NewShoppingCartItem[]){
    return this.sendItemsPost(newItems, {many:'True', override:'True'});
  }

  createItemPost(newItem: ProdottoItem, choice: ProdItemChoice){
    this.new_item = { prodotto: Number(newItem.id), quantità: 1, choice_id: null};
    // && newItem.options[0].choices serve per evitare di inserire nel carrello prodotti senza opzioni
    // come se ne avessero (la choice rimane sporca dopo aver inserito un prod con opzioni..)
    if(choice && newItem.options[0] && newItem.options[0].choices){
      this.logger.log('createItemPost() ha inserito la choice_id = '+choice.id);
      this.new_item.choice_id = choice.id;
    }
    return this.sendItemPost(this.new_item, {override:'False'});
  }

  updateItemsPost(newItems: any[]){
    // Metodo usato SOLO durante il MERGE DEL CARRELLO locale a remoto
    var new_items = [];
    for(let x of newItems){
      if(x['quantità'] && x['key'] && x['prezzo_vetrina'] && x['costo']){
        var new_key = x['key'].substring(13,x['key'].length);
        if(new_key && new_key.includes('-')){
          // Se la key è composta da prodotto.id-prodotto.choice_id allora tengo solo la prodotto.id
          new_key = new_key.split("-",1)[0];
        }
        var new_i = {prodotto: Number(new_key), quantità: x['quantità'], choice_id:null};
        if(x['choice_id']){
          new_i.choice_id = x['choice_id'];
        }
        new_items.push(new_i);
      }else{
        this.logger.log('updateItemsPost: conversione di UN item del carrello fallita');
      }
    }
    if(new_items.length>0){
      this.logger.log('updateItemsPost: POST inviata');
      return this.sendItemsPost(new_items, {many:'True', override:'False'});
    }else{
      this.logger.log('updateItemsPost: POST annullata, conversione del carrello locale errata');
      return null;
    }
  }

  private sendItemsPost(newItems: NewShoppingCartItem[], params: {}) {
    const postData: NewShoppingCartItem[] = newItems;
    // {res: string}
    return this.http
      .post<ShoppingCartUpdate[]>(
        this.defaultUrl,
        postData,
        {
          headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
          params: params,                                     
          responseType: 'json',
          observe: 'response'
        }
      );
  }

  private sendItemPost(newItem: NewShoppingCartItem, params: {}) {
    const postData: NewShoppingCartItem = newItem;
    return this.http
      .post<{res: string}>(
        this.defaultUrl,
        postData,
        {
          headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
          params: params,                                     
          responseType: 'json',
          observe: 'response'
        }
      );
  }

}
