import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpEventType,
  HttpEvent
} from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { AddOrUpdateVendorProductReturn, ImmagineProdottoSecondaria, Prodotti, ProdottoItem } from '../models/Prodotti.model';
import { AuthService } from './auth.service';
import { UserService } from './User.service';
import { ConstantUtilsService } from './constant-utils.service';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class GetProdottiService {

  constructor(
    private constantUtils: ConstantUtilsService,
    private http: HttpClient, private logger: NGXLogger,
    private authService: AuthService,
    private userService: UserService) {}
    
  private defaultUrl: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/products/')
  private add_vendor_product_url: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/vendors/products/')
  private vendor_product_url_base: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/vendors/products/')
  private vendor_product_image_UPDATE_url: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/vendors/products/images/')
  private product_url_base: string = this.constantUtils.getFullURL(this.constantUtils.API_PATH+'/products/')
  error = new Subject<string>();

  ADD_PRODUCT_SUCCESS = true;
  ADD_PRODUCT_ERROR = false;

  UPDATE_PRODUCT_SUCCESS = true;
  UPDATE_PRODUCT_ERROR = false;


  // HTTP GET
  fetchProductsByParams(){
    // TODO: Per ordinare i risultati, guarda il servizio getUtentiVenditori
    /*let searchParams = new HttpParams();
    searchParams = searchParams.append('print', 'pretty');
    searchParams = searchParams.append('custom', 'key');*/ 
    const params = null;
    // TODO take search params 
    return this.fetchProducts(this.defaultUrl, params); 
  }

  fetchProductsByURL(url: string){
    return this.fetchProducts(url, null)
  }

  fetchProducts(url, searchParams){

    return this.http
    .get<Prodotti>(
      url,
      {
        headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
        params: searchParams,                                     // TODO: Per ordinare i risultati, guarda il servizio getUtentiVenditori
        responseType: 'json'
      }
    )
    .pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        // Send to analytics server
        return throwError(errorRes);
      })
    );
  }

  // HTTP DELETE
  deleteVendorProduct(productId: number){
    if(!this.authService.isLoggedIn()){
      this.logger.log("UserService - fetchMyProfile", "user is not logged in. Exiting.");
      return Promise.resolve(null);
    }

    if(!this.userService.userIsVendor()){
      console.error("getProdottiService", "deleteVendorProduct: Attempt of adding new product, but user is not a vendor");
      return Promise.resolve(this.ADD_PRODUCT_ERROR); // Ritorna un false
    }

    const request_url = this.vendor_product_url_base + productId + '/'

    return this.http.delete(
      request_url, {
        observe: 'events',
        responseType: 'text'
      }).toPromise().then(
        (responseData) => {
          this.logger.log("deleteVendorProduct - Product delete result", responseData)
          return this.UPDATE_PRODUCT_SUCCESS;
        },
        errorRes => {
          this.logger.log("deleteVendorProduct - Product delete error", errorRes)
          return this.UPDATE_PRODUCT_ERROR;
        }
      )
  }

  fetchProductsByUrlAndParams(url, params){
    if(url!=null){
      // after the first time
      return this.fetchProducts(url,params);
    }else{
      // the request for the first 10 objects
      return this.fetchProducts(this.defaultUrl,params);
    }
  }

  addNewVendorProduct(productData){
    // 'vendors/products/'
    if(!this.authService.isLoggedIn()){
      this.logger.log("getProdottiService - addNewVendorProduct", "user is not logged in. Exiting.");
      return Promise.resolve(null);
      // TODO handle
    }

    if(!this.userService.userIsVendor()){
      console.error("getProdottiService", "addNewVendorProduct: Attempt of adding new product, but user is not a vendor")
      return Promise.resolve(this.ADD_PRODUCT_ERROR)
    }

    const httpHeaders = new HttpHeaders({ 
      'Content-Type' : 'application/json',
      // 'Authorization':"Bearer "+ this.userTokenData.access_token
    }); 

    return this.http
      .post(this.add_vendor_product_url, productData, {headers: httpHeaders, responseType: 'json'})
      .toPromise().then(
        (responseData: ProdottoItem) => {
          this.logger.log("getProdotti - Product creation result", responseData)
          this.logger.log("getProdotti - New Product ID = "+responseData.id)
          const response: AddOrUpdateVendorProductReturn = {
            result: this.ADD_PRODUCT_SUCCESS,
            id: responseData.id
          }
          return response;
        },
        errorRes => {
          this.logger.log("getProdotti - Product creation error", errorRes)
          const response: AddOrUpdateVendorProductReturn = {
            result: this.ADD_PRODUCT_ERROR,
            id: 'Error'
          }
          return response;
        }
      )
  }

  updateVendorProduct(productId, productData, logLabel: string){
    if(!this.authService.isLoggedIn()){
      this.logger.log("UserService - fetchMyProfile", "user is not logged in. Exiting.");
      return Promise.resolve(null);
      // TODO handle
    }

    if(!this.userService.userIsVendor()){
      console.error("getProdottiService", "addNewVendorProduct: Attempt of adding new product, but user is not a vendor")
      return Promise.resolve(this.ADD_PRODUCT_ERROR)
    }

    const request_url = this.vendor_product_url_base + productId + '/'

    const httpHeaders = new HttpHeaders({ 
      'Content-Type' : 'application/json',
    }); 

    return this.http
      .put(request_url, productData, {headers: httpHeaders, responseType: 'json'})
      .toPromise().then(
        (responseData: ProdottoItem) => {
          this.logger.log("updateVendorProduct["+logLabel+"] - Product update result", responseData)
          const response: AddOrUpdateVendorProductReturn = {
            result: this.ADD_PRODUCT_SUCCESS,
            id: responseData.id
          }
          return response;
        },
        errorRes => {
          this.logger.log("updateVendorProduct - Product update error", errorRes)
          const response: AddOrUpdateVendorProductReturn = {
            result: this.ADD_PRODUCT_ERROR,
            id: 'Error'
          }
          return response;
        }
      )
  }

  postSecondaryVendorProductImage(newImg){
    if(!this.authService.isLoggedIn()){
      this.logger.log("UserService - fetchMyProfile", "user is not logged in. Exiting.");
      return Promise.resolve(null);
      // TODO handle
    }

    if(!this.userService.userIsVendor()){
      console.error("getProdottiService", "postSecondaryVendorProductImage: Attempt of adding a new secondary image, but user is not a vendor")
      return Promise.resolve(this.ADD_PRODUCT_ERROR)
    }

    this.logger.log(newImg);

    const httpHeaders = new HttpHeaders({ 
      'Content-Type' : 'application/json',
    }); 

    return this.http
      .post(this.vendor_product_image_UPDATE_url, newImg, {headers: httpHeaders, responseType: 'json'})
      .toPromise().then(
        (responseData) => {
          this.logger.log("postSecondaryVendorProductImage - Image creation result", responseData)
          return this.UPDATE_PRODUCT_SUCCESS;
        },
        errorRes => {
          this.logger.log("postSecondaryVendorProductImage - Image creation error", errorRes)
          return this.UPDATE_PRODUCT_ERROR;
        }
      )
  }

  updateSecondaryVendorProductImage(pk: number, newImg){
    if(!this.authService.isLoggedIn()){
      this.logger.log("UserService - fetchMyProfile", "user is not logged in. Exiting.");
      return Promise.resolve(null);
      // TODO handle
    }

    if(!this.userService.userIsVendor()){
      console.error("getProdottiService", "updateSecondaryVendorProductImage: Attempt of updating product image, but user is not a vendor")
      return Promise.resolve(this.ADD_PRODUCT_ERROR)
    }

    this.logger.log(pk);
    this.logger.log(newImg);

    const url = this.vendor_product_image_UPDATE_url + pk + '/';

    const httpHeaders = new HttpHeaders({ 
      'Content-Type' : 'application/json',
    }); 

    return this.http
      .put(url, newImg, {headers: httpHeaders, responseType: 'json'})
      .toPromise().then(
        (responseData) => {
          this.logger.log("updateSecondaryVendorProductImage - Image update result", responseData)
          return this.UPDATE_PRODUCT_SUCCESS;
        },
        errorRes => {
          this.logger.log("updateSecondaryVendorProductImage - Image update error", errorRes)
          return this.UPDATE_PRODUCT_ERROR;
        }
      )
  }

  deleteSecondaryVendorProductImage(pk: number){
    if(!this.authService.isLoggedIn()){
      this.logger.log("UserService - fetchMyProfile", "user is not logged in. Exiting.");
      return Promise.resolve(null);
      // TODO handle
    }

    if(!this.userService.userIsVendor()){
      console.error("getProdottiService", "deleteSecondaryVendorProductImage: Attempt of deleting product image, but user is not a vendor")
      return Promise.resolve(this.ADD_PRODUCT_ERROR)
    }

    this.logger.log(pk);

    const url = this.vendor_product_image_UPDATE_url + pk + '/';

    const httpHeaders = new HttpHeaders({ 
      'Content-Type' : 'application/json',
    }); 

    return this.http
      .delete(url, {headers: httpHeaders, responseType: 'json'})
      .toPromise().then(
        (responseData) => {
          this.logger.log("updateSecondaryVendorProductImage - Image delete result", responseData)
          return this.UPDATE_PRODUCT_SUCCESS;
        },
        errorRes => {
          this.logger.log("updateSecondaryVendorProductImage - Image delete error", errorRes)
          return this.UPDATE_PRODUCT_ERROR;
        }
      )
  }

  getProduct(productId){
    const productURL = this.product_url_base + productId
    return this.http
    .get<ProdottoItem>(
      productURL,
      {
        headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
        responseType: 'json'
      }
    )
    .pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        // Send to analytics server
        return throwError(errorRes);
      })
    );
  }

  // This method is used only by authenticated users in order to get the product
  // AND to get the info 'Is it reviewable?'
  getProductAuthUser(productId){
    const productURL = this.product_url_base + productId + '/review/'
    return this.http
    .get<ProdottoItem>(
      productURL,
      {
        headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
        responseType: 'json'
      }
    )
    .pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        // Send to analytics server
        return throwError(errorRes);
      })
    );
  }

}
